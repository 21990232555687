
.consommable-card{
	padding: 10px;
	border: 1px solid #eee;
	cursor: pointer;
	span:not(:first-child){
		font-size: 12px;
	}
}
.complement{
	display: inline;
}

