
$level-icon-height: 16px;
$level-icon-width: 16px;
ul{
    list-style-type: none;
}
li{
    list-style-type: none;
}
    .ifcEntity{
        cursor: pointer;
        padding: 5px 0;
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    .ifcEntity:hover{
        background-color: rgba(233, 222, 237, 0.5);
    }
    .ifc-entity-selected{
        background-color: rgb(233, 222, 237);
    }
  .badge-75 {
    --badge-size: 75px;
  }

  .badge-200 {
    --badge-size: 200px;
  }

  .badge {
    /* Un rond vert */
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    background-color: limegreen;
    border: calc(var(--badge-size) / 10) solid lightgray;
    /* Des initiales blanches */
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(var(--badge-size) / 1.75);
    font-weight: bold;
    /* Et centrées */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

.arborescence-header{
    width:100%;
    background-color:#E8EAEE;
    font-size:13px;
    padding:5px 20px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    margin-bottom: 10px;
}
.level-icon{
    width: $level-icon-height;
    height: $level-icon-height;
}
.viewer-filter{
    border: 1px solid #35b1ea;
    padding: 0 5px;
    .subtitle{
        font-size: 11px;
    }
}
.screen-centered{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 8px 12px;
  cursor: pointer;
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-wrapper span {
  display: inline-block;
  vertical-align: middle;
}

.file-input-label {
  margin-left: 8px;
}
