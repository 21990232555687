
.vg-periode-selector-week{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	>span{
		height: 32px;
		width: 32px;
		text-align: center;
		background-color: #dcdfe6;
		border-radius: 100%;
		color: grey;
		padding-top: 6px;
		margin-right: 10px;
	}
	>span:hover{
		cursor:pointer;
		font-weight: bold;
	}
	.day-selected{
		font-weight: bold;
		color: #fff;
		background-color: #3999ff;
	}
}
