
.parametres-corps-detat{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
	.liste{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap:10px;
		width: 100%;
		.corps-detat{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap:10px;
			border-bottom: 1px solid whitesmoke;
			width: 100%;
			padding: 10px;
		}
	}
}
