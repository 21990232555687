
.uploader-container{
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;

	input[type="file"] {
	    display: none;
	}
	.upload-photo{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: baseline;
		border: 1px solid #ccc;
		border-radius: 5px !important;
		padding: 6px 12px;
		cursor: pointer;
		>span{
			padding-left: 5px;
		}
	}
	.select-tag{
		width: 50%;
	}
	.upload-file{
		border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
		width: 50%;
	}
	.upload-pi{
		padding: 0px 5px !important;
		font-size: 10px;
		font-weight: normal;
	}
	.upload-disabled{
		cursor: not-allowed;
	}
	.upload-button:not(.upload-disabled):hover{
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
	}
	.upload-button:not(.upload-disabled):active,.upload-button:not(.upload-disabled):focus{
	  box-shadow: 0 1px #00cc66;
	}
	
	.upload-photo-info{
		font-weight: normal;
		background-color: #059CFF !important;
		color: white !important;
		border: none !important;
		margin-bottom: 0px !important;
	}
	.upload-button-sm{
		line-height: 11px;
		font-size: 1.1rem;
		font-weight: normal;
	}
	.upload-button-md{
		
	}
}
.uploader-container-file{
	width: 100%;
	border: 1px solid whitesmoke;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:10px;
	.file{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 5px;
	}
}
.hidden-file-input {
  display: none;
}
