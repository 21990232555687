#genereQrCode{
  .optionPanel{
    display: none;
  }
}
.tableQrCodeGenerate{
  overflow: auto;
  height: 400px;
  width: 100%;
}
.optionPanelQrCode{
  margin-bottom: 20px;
}
#qrCodeGeneratorBERD{

  th, td{
    padding: 10px;
    width: 5em;
  }

}
