
// on personnalise le label comme on veut
.btn-action{
  margin: 0 5px;
}
.mobile-actions-buttons{
    display:flex;
    flex-direction:column;
    gap:5px;
}
.desktop-actions-buttons{
    display:flex;
    flex-direction:row;
    gap:5px;
    margin-bottom: 10px;
}
