
#consommables{
	height: 100%;
}
.btn-new-consommables{
    background-color: #5EBC55;
    top: 6px;
    right: 35px;
    height: 40px;
    padding-top: 10px;
    transition: background-color .5s ease;
    background-color: #5EBC55 !important;
    color: white !important;
    border: 1px solid #5EBC55 !important;
}
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
}
.label-file:hover {
    color: #535353;
}

// et on masque le input
.input-file {
    display: none;
}
