
.vg-composants-table{
    height: 100%;
}
.vg-composants-liste{
    height: 100%;
    overflow: auto;
    .empty-composants-message{
        color: rgb(57, 153, 255);
    }
}
