
.affectation-fm-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    .affectation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap:5px;
        img{
            width: 15px;
            height: 15px;
        }
    }
}
