
.operation-textuelle-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    >span{
        width: calc(100% - 15px);
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
