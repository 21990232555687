
.vg-equipement-actives-taches{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
	width:100%;
	.equipement-tache{
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5px;
		padding: 10px 0;
	}
}
