
.btn-save-replace-qr-code{
    font-size: 11px !important;
}
.replace-qr-code{
    .grey-color{
        color:#b8b8b8;
    }
    .info-equipement{
        margin:20px 0;
    }
    .old-info{
        margin-bottom: 20px;
        span{
            margin-right: 15px;
        }
    }
    .new-info{
        >.grey-color{
            margin-bottom: 5px;
        }
        .scan-qr-code{
            display: flex;
            form{
                display: flex;
                width: 100%;
            }
            .vg-input-block{
                width: 90%;

            }
            img{
                margin: 0;
                height: 30px;
                margin-left: 10px;
            }
        }
    }

}

