
.flexRow{
    display:flex;
    /*justify-content: space-between;
    width: 30%;*/
}
.flexCol{
    display:flex;
    flex-direction:column
}
.title{
    color:grey;
    margin-bottom: 15px;
}
.value{
    color: #353333;
    font-weight: 600;
    margin-bottom: 5px;
}
.vg-equipement-donnees-financiere{
    border: 1px solid lightgrey;
    padding: 20px 15px;
}
