
.vg-consommable-colisage-selector{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
    span{
        color: rgb(158, 157, 169);
        font-family: "Open Sans";
        font-size: 12px;
    }
}
