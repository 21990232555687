
.vg-intervention-modal{
    .modal-body{
        min-height: 300px;
    }
}
.datetime-input-inline{
    display:flex;
    justify-content:flex-start;
}
@media only screen and (max-width: 768px) {
    .datetime-input-inline{
        display: flex;
        flex-wrap: wrap;
    }
}
