
.dashboard-Cell{
    padding-right: 15px;
    .card-head{
        margin-bottom: 5px;
        .head-name{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img{
                margin:0;
            }
            span{
                margin-right: 10px;
            }
            .libelle-tache-verification {
                font-size: 15px;
                font-weight: bold;
                position: relative;
                top: 2px;
            }
        }
        .affectation{
            display: flex;
            align-items: center;

            span{
                margin-right: 5px;
            }
        }

        .informations-temporelles {
            margin: 4px 0px ;
            .temps-restant {
                float: right;
            }
        }
    }

    .dashVerif-card-body-taskDone{
        width: 50%;
        float: left;
    }


    .dashVerif-card-body-totalTask{
        text-align: right;
    }
    .txt-date{
        color:#a2a2a2;
    }
}

@media (max-width: 768px){
    .dashboard-Cell{
        font-size: 12px;
        padding:0;
        .libelle-tache-verification{
            font-size: 14px;
        }

    }
}

