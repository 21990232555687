
.vg-email-already-taken{
	display:flex;
	width: inherit;
	padding: 10px;
	background-color: #f8d7da;
	margin-bottom: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	font-size: 12px;
	span{
		color: #aa585c;
	}
}
