
    .libel {
		font-weight: bold;
        height: 100%;
        display: flex;
        align-items: center;
        img{
            height: 20px;
            width: 20px;
            margin-right:10px;
            vertical-align:middle;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
