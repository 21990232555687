
$heightTotal:35px;
.vg-input-number{
    height: $heightTotal;
}
.inputNumber-moins{
    height: $heightTotal;
    width: $heightTotal;
    float: left;
    font-family: "Open Sans";
    background-color: #E4E4E4;
    font-weight: bold;
    border:1px solid rgb(185, 185, 185);
}
input{
    height: $heightTotal;
    float: left;
    text-align: center;
    // font-weight: bold;

    width: 60px;
}
.inputNumber-plus{
    @extend .inputNumber-moins ;
}
