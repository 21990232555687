
#enteteMyAccount{
	height: 50px;
	background: #57C1DB;
	vertical-align: middle;
	padding: 12px;
	color:white;
	.fa-user-circle{
		float: left;
		margin-right: 10px;
	}
	div{
		padding: 4px;
	}
}
.informationUtilisateur{
	padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-left: 25px;
	.informationName{
		font-size: 17px;
	    margin-bottom: 13px;
	    font-weight: 400;
	}
	.informationEmail{
		margin-bottom: 13px;
		color:#c3c3c3;
		font-size: 13px;
	}
	.informationRole{
		font-size: 13px;
		margin-bottom: 15px;

	}

}
.btnDeconnexion{
	border-top: 1px solid #d1d1d1;
	padding: 15px 25px;
	color:#77CDE2;
	font-size: 13px;
	cursor:pointer;
	&:hover{
		background-color: rgb(250, 250, 250);
	}
}
