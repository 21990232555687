
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:700;
  pointer-events: none;
}

.overlay-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:800;
  pointer-events: none;
}

.image-viewer-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:900;
  pointer-events: auto;
}

.image-viewer {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
  pointer-events: auto;
  z-index:900;
}

.actions-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index:980;
  pointer-events: auto;
}

.action-btn {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:hover {
  background: rgba(255, 255, 255, 0.4);
}

.pdf-viewer{
  width: 80vw;
  height: 90vh;
  pointer-events: auto;
}
.pdf-viewer canvas {
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
}
