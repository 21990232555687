
.container-actions{
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
.container-actions-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    >button{
        width: 100%;
    }
}
.container-actions-infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    .container-action-info{
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width:100%;
    }
    >span{
        font-size: 12px;
        color: #BBBCBE;
        line-height: 1;
    }
}
.container-action-arrow{
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

}
.container-actions-btn-livraison{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
>button{
    width: 50%;
    margin: 10px 0;
}
}
.bc-total{
display:flex;
flex-direction:column;
align-items: flex-end;
margin-right: 6%;
.bc-total-item{
    display:flex;
    justify-content:space-between;
    align-items: baseline;
    .bc-total-item-title{
        width: 100px;
        color: #9e9da9;
        font-family: "Open Sans";
        font-size: 0.85em;
    }
}
}
.fiche-curative-link{
color: #3999ff;
margin-right: 10px;
}
.fiche-curative-link:hover{
cursor: pointer;
}
