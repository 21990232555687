
$color-green: #50b659;
$box-shadow-color: rgba(0,0,0,0.2);
$box-shadow-hover-color: rgba(0,0,0,0.3);
$box-shadow-active-color: rgba(0,0,0,0.4);
/*
* $name : prefix du nom de la class vg-button
* $background-color : background du bouton
* $disabledcolor : Couleur si bouton disabled
* $blackColor : Couleur de la police en noir?
*/
@mixin vgButtonMixin($name,$background-color,$disabledcolor,$blackColor:true) {

    .vg-button-#{$name}{
        @if $blackColor{
            color:black;
        }
        @else{
           color: white;
        }

        border: 1px solid $background-color;
        background-color: $background-color;
    }
    .vg-button-#{$name} {
        box-shadow: 0 1px $disabledcolor;
    }
    .vg-button-#{$name}:disabled,
    .vg-button-#{$name}[disabled]{
        cursor:not-allowed;
        opacity: 0.6;
        background-color: $disabledcolor;
    }
}
/*
* $name : prefix du nom de la class vg-button
* $color : Couleur de la police
*/
@mixin vgButtonDefaultMixin($name,$color){
    .vg-button-default-#{$name}{
        color: $color;
        border: 1px solid $color;
        background-color: white;
    }
    .vg-button-default-#{$name}:active,.vg-button-default-#{$name}:focus {
      box-shadow: 0 2px lighten($color,20%);
    }
}
.vg-button {
  /*float: right;*/
  /*margin-right: 10px;*/
  display: flex;
  justify-content: center;
    align-items: center;
    gap:5px;
  line-height: 1;
  height:30px;
  /*white-space: nowrap;*/
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: 0.1s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /*padding: 10px 10px;*/
  font-size: 12px;
  position: relative;
  /*overflow: hidden;*/
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
  font-family: "Open Sans";
  /* font-size: 1em; */
  border:none;
  /*height:fit-content;*/
  border-radius: 5px;
  box-shadow: 2px 2px 5px $box-shadow-color;
}
.vg-button:hover {
    box-shadow: 4px 4px 10px $box-shadow-hover-color;
}
.vg-button:active{
    /*box-shadow: 0 1px $box-shadow-active-color;*/
}
.vg-button:disabled,
.vg-button[disabled]{
  cursor:not-allowed;
  opacity: 0.6;
  background-color: #f7f7f7;
}

/* -------------------- */
/*     default button      */
/* -------------------- */
.vg-button-default{
    color: $color-green;
    border: 1px solid $color-green;
    background-color: white;
}

/* -------------------- */
/* normal-action button */
/* -------------------- */
@include vgButtonMixin("success",$color-green,$color-green,false);
/* ------------------------- */
/*  dangerous-action button  */
/* ------------------------- */
@include vgButtonMixin("danger",#f36f3a,#ff7b5c,false);
/* -------------------------------- */
/* info button  */
/* -------------------------------- */
@include vgButtonMixin("info",#059cff,#4fbeff,false);
/* -------------------------------- */
/* grey button  */
/* -------------------------------- */
@include vgButtonMixin("grey",#f0f0f0,#e2e2e2);
/* -------------------------------- */
/* default-dangerous-action button  */
/* -------------------------------- */
@include vgButtonDefaultMixin("danger",#f36f3a);
/* -------------------------------- */
/* default-dangerous-action button  */
/* -------------------------------- */
@include vgButtonDefaultMixin("info",#059cff);
/* -------------------------------- */
/* xs sm md button  */
/* -------------------------------- */
.vg-button-xs{
    padding: .5rem .5rem;
    font-size: .875rem;
    height:20px;
}
.vg-button-sm{
    padding: .50rem .50rem;
    font-size: 1.1rem;
    height:23px;
}
.vg-button-lg{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    height:40px;
    font-size: 1.4rem;
}

/* -------------------------------- */
/* vg default round button */
/* -------------------------------- */
.vg-button-default-round{
    color: #000;
    border: none;
    background-color: white;
    border-radius: 50%;
}
.vg-button-default-round:active {
    box-shadow: 0 2px #4fbeff;
}

/* -------------------------------- */
/* vg default link */
/* -------------------------------- */
.vg-button-default-link{
    color: #000;
    border: none;
    background-color: transparent;
    box-shadow: none;
}
.vg-button-default-link:active {
    box-shadow: 0 2px #4fbeff;
}

.vg-button-success-link{
    color: $color-green;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    box-shadow: none;
}
.vg-button-danger-link{
    color: #f36f3a;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    box-shadow: none;
}
.vg-button-info-link{
    color: #059cff;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    box-shadow: none;
}
.vg-button-link{
    letter-spacing: -0.5px;
}
.vg-button-link:hover{
    box-shadow: none;
    font-weight: bold;
}
/* -------------------------------- */
/* vg button locked */
/* locked for your role - as a disabled button with no pointer event & with a padlock & with a tooltip */
/* -------------------------------- */
.vg-button-locked{
    color: black;
    border: 1px solid #ddd;
    background-color: #ddd;
    /*pointer-events:none;*/
}

.vg-button-pulse {
    -webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.vg-button-pulse:hover{
    -webkit-animation: none;-moz-animation: none;-ms-animation: none;animation: none;
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);}}

.vg-button-lighter-pulse {
    -webkit-animation: lighterPulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: lighterPulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: lighterPulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: lighterPulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.vg-button-lighter-pulse:hover{
    -webkit-animation: none;-moz-animation: none;-ms-animation: none;animation: none;
}

@-webkit-keyframes lighterPulse {to {box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);}}
@-moz-keyframes lighterPulse {to {box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);}}
@-ms-keyframes lighterPulse {to {box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);}}
@keyframes lighterPulse {to {box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);}}

