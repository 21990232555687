
.flex-row{
    width: 100%;
    display:flex;
    overflow-wrap: anywhere;
    justify-content:space-between;
    margin-bottom:15px;
    align-items: center;
    .flex-item-main{
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .vg-input-block{
            width: 60%;
            padding-right: 10px;
            margin-bottom: 0px !important;
        }
    }
}
.hackBtnAjout{
    margin-top: 15px;
}
.margin-left-5{
    margin-left: 5px;
}
