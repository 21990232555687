
.reponse-index{
	width: 100%;
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	.text-reponse{
		color: #74767a;
		font-size: 16px;
	}
	.input-reponse{

	}
}
