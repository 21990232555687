
.detail-fichesav-modal{
    .fichesav-info{
        display: flex;
        > div{
            margin-bottom: 10px;
            &:first-child {
                color: lightgray;
                width: 40%;
            }
        }
    }
}

.fichesav-description{
    margin-bottom: 15px;
    .fichesav-localisation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
}
