
.vgCategorieViewer{
    height: 100%;
}
.header{
    background-color: #f5f7f7;
    width: 100%;
    padding: 0px 10px;
    height: 42px;
    border: 1px solid #bdc3c7;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.body{
    height: calc(100% - 42px);
}
