
.section{
    margin-bottom: 5px;
}
.section>span{
    font-size: 11px;
    color: #a6a5a3;
}
.section>div{
    height: 50px;
    overflow: auto;
}
.section-details{
    .title{
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        img{
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
    .content{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 10px;
            >div{
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                >span:first-child{
                    width: 80px;
                    color: #a6a6a6;
                }
            }

        }
        .right{
            margin-left: 5px;
            font-size: 10px;
            color: white;
            >*{
                padding: 2px 4px;
            }
            .statut-validee{
                background-color: green;
            }
            .statut-non-validee{
                background-color: red;
            }
        }
    }

}
.section-equipements{
    .default-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .equipements-container{
        height: 100px;
        border: 1px solid #dfdfdf;
        .equipement-row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0 5px 5px;
            .equipement{
                display:flex;
                justify-content:flex-start;
                align-items: center;
                >img{
                    margin-right: 10px;
                }
            }
        }
    }
}
