
.consommable-quantite{
	display:flex;
	justify-content:space-between;
	align-items:baseline;
	gap:10px;
}
@media only screen and (max-width: 1200px){
	.consommable-quantite{
		width: 100%;
		display:flex;
		justify-content:flex-start;
		align-items:baseline;
		gap:10px;
		flex-wrap: wrap;
	}
}
