
.flat-list {
    margin: 0;
    list-style: none;
}

.flat-list li {
    position: relative;
    padding-bottom: 20px;
}
.flat-list-item-tail{
    position: absolute;
    left: 4px;
    height: 100%;
    border-left: 2px solid #e4e7ed;
}
.flat-list-item-node {
    position: absolute;
    background-color: #e4e7ed;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flat-list-item-node-normal {
    left: -1px;
    width: 12px;
    height: 12px;
}
.flat-list-item-wrapper {
    position: relative;
    padding-left: 28px;
    top: -3px;
}
.flat-list-item-content{
    margin-top: 8px;
}
.flat-list-item-bottom{
    color: #909399;
    line-height: 1;
    font-size: 12px;
}
.secondary_section_title{
    color: #35b1ea;
    font-size: 16px;
}
.no-margin{
    margin: 5px !important;
}
.menu-more-item{
    border-bottom: 1px solid whitesmoke;
    width: 100%;
    padding: 10px 0;
}
