
.viewer-photo-container{
	width: 100%;
	padding: 10px;
	.header{
		>span{
			font-weight: bold;
			font-size: 11px;
			padding: 10px;
		}
		display: flex;
		justify-content: flex-end;
	}
	.body{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		>i{
			color: black;
			cursor: pointer;
		}
		>i:hover{
			border-bottom: 1px solid #f5f7f7;
		}
		>i.inactive{
			color: #e0e0e1;
		}
		>div.no-files{
			color: #e0e0e1;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
