
.vg-collapse{
    /*padding: 10px;*/
}
.section{
    >.collapse-header{
        color: #35b1ea;
        font-size: 16px;
    }
}
.collapse-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .collapse-header-pre{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        *:last-child{
            margin-right: 10px;
        }
    }
}
.collapse-header-main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        >span{
            white-space: nowrap;
            margin-right: 10px;
        }
    }
    .collapseur{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
.collapse-content{
    margin: 10px 0px;
}
.default-message{
    font-size: 11px;
}
