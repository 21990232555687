
.composant-cell-renderer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .composant{
        width:80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 0;
        >i{
            margin-right: 20px;
        }
        >img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        .libel{
            color: #3999ff;
            font-weight: bold;
            margin-right: 10px;
            width: 30%;
            min-width: 180px;
        }
        .type{
            font-size: 11px;
            margin-right: 10px;
            width: 20%;
            min-width: 120px;
        }
        .problems-counter{
            font-size: 11px;
            font-style: italic;
        }
    }
}
.composant-cell-renderer:hover{
    background-color: whitesmoke;
}
