
.reponse-boolean{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid #d7d7d9;
	.reponse{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		.icon{
			width:3px;
			height: 14px;
			background-color: rgb(243, 111, 58);
		}
		img{
			width:20px;
			height:20px;
		}
		span{
			color: #74767a;
			font-size: 16px;
		}
		.complement{
			color: lightblue;
		}
	}
	.check{
		img{
			width:20px;
			height:20px;
		}
	}
}
