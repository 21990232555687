
.vg-consommable-datalist{
    width:100%;
    >input{
        width:100%;
    }
    .datalist-container{
        padding:15px;
        z-index:100;
        position:absolute;
        border:2px solid #dcdfe6;
        background-color:#fff;
        .datalist-container-header{
            display:flex;
            justify-content:space-between;
            align-items: center;
            margin-bottom: 10px;
        }
        .datalist-container-body{
            display:flex;
            flex-direction:column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            max-height:200px;
            overflow:scroll;
            .consommable{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                cursor:pointer;
            }
            .consommable:hover{
                /*background-color: whitesmoke;*/
                font-weight: bold;
            }
        }
    }
}
