
.leaflet-div-icon{
    border: white !important;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaflet-marker-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
