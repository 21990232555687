
.bc-items-table{

    thead, tbody tr{
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead{
        color: #9e9da9;
        font-family: "Open Sans";
        font-size: 0.85em;
        height:50px;
        width: calc( 100% - 1em );
    }
    tbody{
        display: block;
        /*max-height: 150px;*/
        width: 100%;
        overflow: auto;
        td{
            padding-right:5px;
        }
    }
}
.item-unite{
    color: #9e9da9;
}
