
.intervention-cell-render{

    .title{
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        img{
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
    .content{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 10px;
            >div{
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                >span:first-child{
                    width: 80px;
                    color: #a6a6a6;
                }
            }

        }
        .right{
            font-size: 10px;
            color: white;
            >*{
                padding: 2px 4px;
            }
            .statut-validee{
                background-color: green;
            }
            .statut-non-validee{
                background-color: red;
            }
        }
    }

}
