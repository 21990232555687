@import "../../style/setting.scss";
$h-input:50px;
#login_button{
    height:$h-input - 3px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: $maintenance-color;
    color: white;
    border: none;
    font-weight: bold;
    &:hover{
        background-color: rgba(255, 217, 22, 0.70);
        transition: background-color 1s ease;

    }
}
.vg-body-login{
    background-color: white !important;
    height: inherit;


    .login-screen{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content-login-page{
            width: 320px;
            height: auto;
            margin: 0px auto;
            div{
                
            }
            >div{
                @media (max-width: 320px){
                    width: 300px;
                }
            }
            .login-img{
                img{height: 35px;}
                text-align: center;
                margin-bottom: 30px;
            }
            .login-form{
                margin-bottom: 10px;
                .login-input{
                    width: 100%;
                    margin-bottom:20px;
                    height: $h-input;
                    padding: 0 10px;
                    &:focus{
                        border-color: $maintenance-color;
                        box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, $maintenance-color 0px 0px 8px;
                    }
                }
                .login-input-password{
                    display: inline-block;
                    width:100%;
                    position: relative;
                    text-align: center;
                    .input_suffix{
                        position: absolute;
                        right: 5px;
                        margin-top: 6px;
                        padding: auto 0;
                        text-align: center;
                        color: black;
                        transition: all .3s;
                        pointer-events: all;
                        border-left: 1px solid black;
                        .input_suffix-inner{
                            padding:0px 5px;
                            text-align: center;
                            color: black;
                            >i{
                                line-height: 36px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .login-footer{
                text-align: center;
                color: $maintenance-color;
                button{
                    height:$h-input - 3px;
                    width: 100%;
                    margin-bottom: 15px;
                    border-radius: 4px;
                    background-color: $maintenance-color;
                    color: white;
                    border: none;
                    font-weight: bold;
                    &:hover{
                        background-color: rgba(255, 217, 22, 0.70);
                        transition: background-color 1s ease;

                    }
                }

            }
        }
        .credits{
            display: flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            padding-bottom: 20px;
            @media only screen and (max-width: 768px){
                padding-bottom: 10px;
            }
            >.logos{
                display:flex;
                justify-content:center;
                align-items:center;
                margin: 20px 0;
                >img{
                    width: 50px;
                }
            }
            >.text{
                font-style: italic;
                font-size: 11px;
                text-align: center;

            }
        }
    }
}
