
$bgc-violet:#7b54a0;
$bgc-orange:#f06f3a;
$bgc-blue:#6699ff;
$bgc-jaune: #fdc02c;
$bgc-vert: #76E476;
$bgc-gris: #d8d9da;
$bgc-dark: #999999;
$text-tab-color: #43454b;
@mixin theTabsColor($bgc){
    border:1px solid rgba($bgc, 0.5);
    border-left:1px solid rgba($bgc, 0.5);
    background-color: rgba($bgc, 0.1);
    &.active{
        background-color: $bgc;
        border-bottom:1px solid #5d6269;
        @if $bgc == $bgc-gris {
            color: $text-tab-color;
        } @else {
            color: white;
        }
    }
}
@mixin borderBottomColor($bgc){
    border-bottom: 3px solid $bgc;
}
.vg-tabs{
    width: inherit;
    height: 34px;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .tab-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        height: 100%;
        padding: 0 22px;
        border: 1px solid grey;
        white-space: nowrap;
        img{
            height: 20px;
            width: 20px;
        }
        .badge-counter{
            font-size: 10px;
            color: #353535;
            font-weight: bold;
        }
    }
    .tab-item:hover{
        cursor: pointer;
        border-bottom: 1px solid #5d6269;
    }
    .orange{
        @include theTabsColor($bgc-orange);
        // background-color: $bgc-orange;
    }
    .violet{
        @include theTabsColor($bgc-violet);
        // background-color: $bgc-violet;
    }
    .bleu{
        @include theTabsColor($bgc-blue);
        // background-color: $bgc-blue;
    }
    .jaune{
        @include theTabsColor($bgc-jaune);
        // background-color: $bgc-jaune;
    }
    .vert{
        @include theTabsColor($bgc-vert);
    }
    .gris{
        @include theTabsColor($bgc-gris);
    }
    .dark{
        @include theTabsColor($bgc-dark);
    }
}
.vg-tabs:hover{
    overflow: auto;
}

.vg-tabs-pane{
    width: inherit;
    height: 34px;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d3d3d3;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .tab-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        height: 100%;
        padding: 0 22px;
        white-space: nowrap;
        img{
            height: 20px;
            width: 20px;
        }
        .badge-counter{
            font-size: 10px;
            color: #353535;
            font-weight: bold;
        }
    }
    .tab-item:hover{
        cursor: pointer;
    }
    .orange{
        &.active{
            @include borderBottomColor($bgc-orange);
        }
    }
    .violet{
        &.active{
            @include borderBottomColor($bgc-violet);
        }
    }
    .bleu{
        &.active{
            @include borderBottomColor($bgc-blue);
        }
    }
    .jaune{
        &.active{
            @include borderBottomColor($bgc-jaune);
        }
    }
    .vert{
        &.active{
            @include borderBottomColor($bgc-vert);
        }
    }
    .gris{
        &.active{
            @include borderBottomColor($bgc-gris);
        }
    }
    .dark{
        &.active{
            @include borderBottomColor($bgc-dark);
        }
    }
}
