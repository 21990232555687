
.equipement-piece-to-check-cell-render{
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:5px;
    padding: 20px 0;
    .content{
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap:5px;
        .derniere-verif{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            small{
                color: rgb(156, 156, 156);
            }
        }
    }
}
