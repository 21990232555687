
	.select-releve{
		height: 100%;
        width: 100%;
		.container-releves{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            overflow-y: auto;
            padding: 0;
            padding-top: 5px;
            .vg-button{
                width:100%;
                margin-right: 0px !important;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                background-color: #fff6ed !important;
                >small{
                    color: grey;
                }
            }
            .info{
				font-size: 11px;
				font-weight: italic;
				color: #606266;
			}
		}
	}
