
  .no-file{
      color: #e0e0e1;
  }
  .vg-input-categorie-selector{
      // text-align: center;
      border: 1px solid #d6d1ce;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 36px;
      padding: 0 10px;
      color: #d6d1ce;
      cursor: pointer;
  }
  .vg-input-categorie-selector:hover{

  }
.vg-categorie-selector-search{
  margin-bottom: 5px;
  margin-top: -10px;

  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #e9e9e9;
  i & .fa{
    width: 2%;
    color:#626262;
  }
  input{
    width: 90%;
    border: none;
    border-bottom: 1px solid white;
    padding-left: 20px;
    padding-bottom: 0;
    height: 30px;
    font-size: 15px;
    color:#626262;
    &:focus{
      border:none;
      height: 35px;
      // border-bottom: 1px solid #b5b5b5;
      box-shadow: 0 0 0 0 lightgrey;
      color:black;
    }
  }
}

.list-container{
  height: calc(100% - 30px);
  overflow-y: scroll;
  /* width */

}
.list-categorie{
  width: 100%;
  display: flex;
  padding: 5px;
  padding-top:15px;
  border-bottom : 2px solid #e9e9e9;
  &.active{
    background-color:rgb(217, 217, 217);
  }
  .color-categorie{
    width: 15%;
    align-self:center;
    float: left;
    >div{
      width: 25px;
      height: 25px;
      margin:auto;
      border-radius: 20px;
    }
  }
  .icone-categorie{
    width: 10%;
    align-self:center;

    float: left;
    img{
      margin:0;
    }
  }
  .label-categorie{
    width: 60%;
    font-size: 13px;
    float: left;
    span{
      font-size: 11px;
      margin-right: 1px;
    }

  }
  .img-categorie{
      width: 15%;
      float: left;
      align-self:center;

      text-align: center;
  }
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
