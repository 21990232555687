

$left-panel-width: 200px;
$main-panel-width: calc(100%-#{$left-panel-width});

.vg-plan-interactif{
    height: 100%;
    width: 100%;
    .plan-interactif{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        height: inherit;
        width: 100%;
        .left-panel{
            width: $left-panel-width;
        }
        .main-panel{
            width: $main-panel-width;
        }
    }
}
.loader-container{
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}
.main-uploader-container{
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    span{
        color: #02a7f0;
        font-size: 24px;
    }
}
.loader-bar{
    width: 100%;
    background-color: #f9b934;
    display: flex;
    justify-content: center;
    align-items: center;
}
