
	.vg-fichesav-description{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 3px;
		overflow: auto;
		.icon{
			height: 20px;
			width: 20px;
			margin-right: 10px;
			margin-top: 4px;
		}
	}
	.dotted-border{
		height: inherit;
		text-overflow: ellipsis;
	}
    .dotted-border:hover{
        text-decoration: underline dotted #00abe2;
        cursor: pointer;
    }
	.inline-text{
		display: inline;
		white-space: nowrap;
		text-overflow: ellipsis;
		width:fit-content;
	}
	@media screen and (max-width: 600px) {
		.icon{
			margin-top: 0px !important;
		}
	}

