
.equipements-list{
	.equipement-row{
		.equipement{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
		}
	}
}
