
.vg-search-datas{
	display: block;
	width: inherit;
	height: inherit;
	color: #333 !important;

	/* Conteneur principal */
	.search-datas-input-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		/*max-width: 400px; /* Ajustez la largeur selon vos besoins */
	}

	/* Champ de saisie */
	.search-datas-input-container input {
		height: 30px;
		width: 100%;
		padding: 0 50px 0 15px; /* Ajuste le padding à droite pour les icônes */
		border: 1px solid whitesmoke;
		border-radius: 5px;
		outline: none;
		box-sizing: border-box;
	}
	.search-datas-input-container input::-webkit-input-placeholder {
		color: #AFB6BF !important;
		font-size: 14px;
	}

	/* Conteneur pour les icônes */
	.input_suffix {
		position: absolute;
		right: 10px; /* Ajustez selon votre besoin */
		display: flex;
		align-items: center;
		gap: 8px; /* Espace entre les icônes */
		cursor: pointer;
	}
	.search-datas-results-menu{
		position: absolute;
		top: 60px;
		left: inherit;
		min-width: 300px;
	}
	.search-datas-results-slot{
		position: relative;
		min-width: 300px;
	}
}
.vg-search-datas-slot{
	height: 36px !important;
	width: 100% !important;
}
.vg-search-datas-lg{
	height: 50px !important;
	input{
		height: 50px !important;
		max-height: 50px !important;
		font-size: 16px !important;
	}
	input::-webkit-input-placeholder {
		font-size: 16px !important;
	}
}
.vg-search-datas-menu{
	height: 30px;
}
@media screen and (max-width: 991.98px) {
	.vg-search-datas{
		width: 100%;
		.search-datas-results-menu{
			z-index: 999;
			min-width: -webkit-fill-available;
		}
		.search-datas-results-slot{
			min-width: -webkit-fill-available;
		}
	}
}
