
.files-selector-container{
	width:100%;
	height: 200px;
	overflow-y: auto;
	border:1px solid #dcdfe6;
	padding:10px;
	display: flex;
	flex-direction:row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	.item{
		max-width:180px;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap:5px;
		padding:5px;
		cursor: pointer;
		>span{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
		.item-icon{
			height: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	.item:hover{
		background-color: whitesmoke;
	}
	.item-selected:hover{
		background-color: rgba(53, 177, 234, 0.3);
	}
	.item-selected{
		background-color: rgba(53, 177, 234, 0.3);
	}
}

