
.vg-tooltip{
    position: relative;
    &:hover{
        .content-tooltip{
            display: block;
            opacity: 1;
            animation-duration: .5s;
            animation-name: vg-opacity;
        }
    }
    .content-tooltip{
        display: block;
        position: absolute;
        background-color: #fbfbfb;
        border: 1px solid #dddddd;
        padding: 5px 15px;
        opacity: 0;
        z-index: 3;
        white-space: normal;

        bottom:30px;
        max-height: 100px;
        overflow: auto;

        span{
            font-weight: normal;
            color:black;
            font-size: 12px;

        }
    }
    .display-tooltip{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}
@keyframes vg-opacity {
  from {
      opacity: 0;
  }

  to {
      opacity: 0;
  }
}
