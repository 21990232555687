
#hubspot-form-container{
    @media only screen and (max-width: 768px){
        font-size: 18px;

    }
    .hs-input{
        width: 100%;
        height: 40px;
        padding: 5px;
        margin-bottom: 15px;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        font-size: 16px;
        color: #a0a6ad;
    }
    .hs-input::placeholder {
        font-size: 16px;
        color: #a0a6ad;
    }

    label{
        display:none;
    }
    .hs-fieldtype-select{
        margin-bottom:15px;
    }
    .hs-button{
        height: 47px;
        width: 100%;
        margin-bottom: 15px;
        margin-top:20px;
        border-radius: 4px;
        background-color: #169bd5;
        color: white;
        border: none;
        font-weight: bold;
        font-size: 16px;
    }
    .text-engage{
        display: flex;
        gap: 10px;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 15px;
        /*margin-top: 24px;*/
        color: #50b9f3;
    }
    
} 
