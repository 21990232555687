
.equipement-mobile-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    margin: auto;
    .checkbox-container{
        display: block;
        margin: auto;
    }
    .checkbox-checked{
        color: #1E90FF;
    }
    .main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        width: calc( 100% - 30px );
        padding: 6px 0;
        .title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
        }
        .content{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .footer{
            width: -webkit-fill-available;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
    }
    .width-100{
        width: 100%;
    }
}
