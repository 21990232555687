
.consommable-details-cellrender{
    .consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommable-stock{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
