
.vg-equipement-historique{
	.historique-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.vg-tabs{

		}
		.more{
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.title{
			width:100%;
			text-align:center;
			margin: 10px 0px;
		}
	}
	.historique-body{
		height:300px;
	}
}
