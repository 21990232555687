
// $properties
// filters panel
$filtersPanelHeader: 60px;

#historique-grandeurs-physiques {
    #historique-grandeurs-physiques__tabs-container {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding-left: 20px;
        // &::-webkit-scrollbar {
        //     display: none;
        // }
        .tab {
            border: 1px solid gray;
            border-radius: 6px 6px 0px 0px;
            margin-right: 1px;
            min-width: fit-content;
            padding: 10px;
            &:last-child {
                margin-right: inherit;
            }
            &.active {
                background-color: #FF6927;
                border: none;
                color: white;
                font-weight: bold;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    #filters-panel {
        border: 1px solid gray;
        height: calc(100vh - 203px);
        #filters-panel__header {
            background-color: #F2F2F2;
            border-bottom: 1px solid gray;
            color: #009eec;
            height: $filtersPanelHeader;
        }
        #filters-panel__body {
            height: calc(100% - #{$filtersPanelHeader});
            overflow: auto;
        }
    }
    #grid-container {
        height: calc(100vh - 203px);
        padding-left: 20px;
    }
}
