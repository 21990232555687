
.vg-etages-table{
    .vg-etages-demande-intervention{
        height: 85%;
        width: 100%;
        overflow: auto;
        min-height: 50px;
    }
}
.loading-overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    .text{
        background-color:#35b1ea;
        color:white;
        border:none;
        padding:10px;
        font-size:14px;
        display: block;
    }
}
