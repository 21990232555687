
.parametres-menu{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	*{
		width: 100%;
	}
	div{
		padding:10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		border-bottom: 1px solid whitesmoke;
		img{
			height: 20px;
			width: 20px;
		}
	}
	div:hover{
		cursor:pointer;
		background-color: whitesmoke;
	}
	.activate-blue{
		background-color: #c9ecfc;
		color: #02a7f0;
		font-weight: bold;
	}

}
