
.vg-button-scan{
    align-self: center;
    height: 57px !important;
    width: 57px !important;
    cursor: pointer;
    white-space: nowrap;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    transition: 0.1s;
    opacity: 0.9;
    background-color: white;
    border:1px solid #ff6928 !important;
    padding: 10px;
    padding-top: 7px;
    img{
        margin:0 !important;
    }
    &:focus{
        box-shadow: 0 1px #ffffff !important;
    }
    &:active{
        box-shadow: 0 1px #ffffff !important;
        background-color: rgba(#ff7a00, 0.19);
    }
}
