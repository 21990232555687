
.vg-tree-node-categorie{
	width: 100%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	.icon-categorie{
		width: 16px;
		height: 16px;
	}
}
