
.credits{
	display: flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	//position: fixed;
	width: 100%;
	bottom: 0px;
	padding-bottom: 20px;
	@media only screen and (max-width: 768px){
		padding-bottom: 10px;
	}
	>.logos{
		display:flex;
		justify-content:center;
		align-items:center;
		margin: 20px 0;
		>img{
			width: 50px;
		}
	}
	>.text{
		font-style: italic;
		font-size: 11px;
		text-align: center;

	}
}
