
#validation-button{
	width: 100%;
	color: white;
    border: 1px solid #50b659;
    background-color: #50b659;
	height: 30px;
}
.help{
	float: right;
}
.main-container-di{
	width:60%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	@media only screen and (max-width: 600px) {
		width:100%;
		gap:10px;
	}
}
.step-validation{
	padding:0 10px;
	width:100%;
	margin-bottom:10px;
}
.lieu-complement{
	border: 1px solid #fbc429;
	width: 100%;
	margin-top: 10px;
	padding: 10px 20px;
	font-size: 11px;
}
.list-item{
	border-bottom: 1px solid #ebeef5;
	cursor: pointer;

	div{
		height: 100%;
		padding: 20px;
	}
	.selected-item{
		border: 1px solid #40a9ff;
	}
	img[class=icon]{
		width: 20px;
		height: 20px;
		margin-right: 20px;
	}
	span[class=libel]{
		font-weight: bold;
	}
	span[class=sublibel]{
		color: grey;
	}
	span[class=tag]{
		font-size: 11px;
		padding: 2px;
		float: right;
		border: 1px solid #fbc429;
	}

}
.file-exist{
	width:100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	>span{
		display: flex;
		align-items: center;
		>i{
			margin-right: 10px;
		}
	}
}
.simpleButton{
    background-color: #9b9b9b;
    border: none;
    color: white;
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.simpleButton:hover{
    cursor:pointer;
    opacity:0.6;
}
/*.form-footer{
	button{
		float: right;
		width: 200px;
		@media only screen and (max-width: 600px) {
			width:100%;
		}
	}
}*/
