
.files-plan-cell-renderer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .files{
        text-decoration: underline;
        color: #007dff;
    }
}
