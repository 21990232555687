

.operation-tache-a-prevoir{
    width: 100%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    padding: 10px 0;
    .main-content{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        width:90%;
    }
    .footer-content{
        width: 100%;
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
    }
}

