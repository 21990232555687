
$tree-border-color: whitesmoke;
$hover-node-bg-color: rgba(233,222,237,.4);
$node-selected-color: rgb(233,222,237);
.vg-tree{
	min-height: 200px;
	max-height: 400px;
	overflow: auto;
	border: 1px solid $tree-border-color;
	font-size: 12px !important;
	.tree-folder{
		list-style-type: none;
		margin-block-start: 0 !important;
		margin-block-end: 0 !important;
		padding-inline-start: 25px !important;
		&.last-tree-folder{
			padding: 0 !important;
		}
	}
	.first-folder{
		padding-inline-start: 15px !important;
	}
	.tree-node{
		list-style-type: none;
	}
	.tree-node:hover{
		background-color: $hover-node-bg-color;
	}
	.tree-node.selected{
		background-color: $node-selected-color;
	}
}
@media only screen and (max-width: 1200px){
	.vg-tree{
		min-height: 200px;
		max-height: 400px;
		.tree-folder{
			padding-inline-start: 15px !important;
		}
		.first-folder{
			padding-inline-start: 0px !important;
		}
	}
}
