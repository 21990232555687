
.bons-de-commande-cards{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    .bon-de-commande-card{
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid whitesmoke;
    }
    .bon-de-commande-card:hover{
        cursor: pointer;
        background-color: whitesmoke;
    }
    .bon-de-commande-card-selected{
        background-color: whitesmoke;
    }
}
