
.tache-quantite-cell-render{
    cursor: pointer;
    text-decoration: underline;
    color: #35b1ea;
    font-size:10px;
}
.red{
    cursor: default;
    text-decoration: none;
    color: red;
}
