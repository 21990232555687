
.vg-calendar-planning{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        .days{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 100%;
            flex-grow: 3;
            .day{
                flex-grow: 1;
                text-align: center;
                background-color: white;
                color: #333;
                font-weight: normal;
            }
            .current-day{
                background-color: #059cff !important;
                color: white;
                font-weight: bold;
                border-radius: 100%;
                height: 30px;
                padding: 4px 0px;
            }
            .today{
                border-bottom: 2px solid #059cff;
            }
        }
        
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        .verbose-day{
            width: 100%;
            text-align: center;
            font-size: 12px;
            background-color: #F0F0F0;
            height: 30px;
            padding: 8px;
        }
        .schedule{
            border-bottom: 1px solid #ccc;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            .schedule-icon{
                width: 20px;
                .icon{
                    height:15px;
                    width: 15px;
                }
                .icon-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                }
            }
            .schedule-description{
                width: calc(100% - 20px - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                font-size: 12px;
                .title{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                }
                .intervention-prevue{
                    color: red;
                }
                .intervention-realisee{
                    color: #45b348;
                }
            }
            .schedule-time-interval{
                width: 40px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

