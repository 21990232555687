//MAJ 16072018
$tab-violet:#7b54a0 ;

$tab-orange : #f06f3a;
$tab-orange-10 : rgba(#7b549f, 0.1);

$tab-jaune: #fdc02c;

$tab-bleu: #6699ff;

// variable page *************
//$bgApp:#F2F3F4;
$bgApp:#fff;
$greyBorder:#999999;
$greyColor:#5b5b5b;
$check: #76CA74;
$cross: #FF422E;
$blue-btn :#0d96fc;
$header-blue: #3A99D4;

// recensement
$colorRecensement: #5EBC55;

// verification
$verif-color:#EE3E35 ;
$verif-color2: #FF6927;
$onglet-verif:#FF6927;

//maintenance
$maintenance-color:#FFC73D;

// filtre
$filtre-color:#98CFEC;
$filtre-size:15px;

// activité verification
$verif-acti-color: #F2F7FC;
// Zoom FM
$Show-fm-color:#6EBCE4;
// modal
$bg-modal:#FAFDFD;
