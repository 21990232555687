
.main-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 10px;
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        .description-equipement{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2px;
            *:first-child{
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
    .search{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .checkpoints-list{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        border-top: 1px solid #d7d7d9;
        .checkpoint{
            width: 100%;
        }
    }
    .last-check{
        text-align: center;
        div{
            margin-bottom: 10px;
        }
        .reponse{
            b{
                font-size: 15px;
            }
        }
        .date{
            font-size: 13px;
        }
    }
    .rubrique{
        border-bottom: solid 1px rgb(68, 68, 68);
    }
}
.counter-non-conforme{
    color: red;
}
