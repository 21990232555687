
.vg-periodicite-shortcuts{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
    .custom{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        gap: 5px;
    }
}
