
.vg-button-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    >*{
        margin-right: 0px !important;
    }
}
