
.categorie-container-select{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .icone-name{
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .color-categorie{
        width: 5%;

        >div{
            width: 25px;
            height: 25px;
            margin:auto;
            border-radius: 20px;
        }
    }
}

