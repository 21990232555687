
.site-mobile-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: inherit;
    height: inherit;
    .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    .complement{

    }
}
