
.select-lieu{
	height: inherit;
	width: inherit;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	.header{
		width: inherit;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.body{
		width: inherit;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 5px;
		.lieu{
			width: inherit;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			padding: 20px;
			.title{
				font-size: 11px;
				color: #333;
				font-weight: bold;
				display: flex;
				justify-content: flex-start;
			    white-space: nowrap;
			}
			.subtitle{
				color: #333;
				font-size: 10px;
			}
		}
	}
}
