
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height:400px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
