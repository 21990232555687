
.vg-equipement-select{
	.equipement-select{
		display: flex;
		border: 1px solid lightgray;
		height: 30px;
		padding: 0 10px;
		align-items: center;
		cursor: pointer;
		.icon{
			width: 20%;
			img{
				margin: 0;
				width: 20px;
				height: 20px;
			}
		}
		.name{
			width: 80%;
		}
	}
	.onglet{
		margin-bottom: 15px;
	}
	.list{
		height: 40vh;
		overflow: auto;
		.list-equipement{
			&.active{
		      background-color:#ebebeb;
			  height: 70px;
		    }
			height: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			border: 1px solid lightgray;
			padding:15px;
			.info{
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			.info-supp{
				display: flex;
				margin-top: 10px;
			}
		}
	}

}

