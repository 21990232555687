
.vg-synthese-maintenance-preventive-table{
	border: 1px solid #BDC3C7;
	font-size: 12px;
	height: 100%;
	.header{
		display: grid;
		grid-template-columns: minmax(200px, 1fr) 4fr;
		height: 40px;
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		border-bottom: 1px solid #BDC3C7;
	}
	.body{
		height: calc(100% - 40px);
		overflow-y: auto;
		.table-row{
			height: 50px;
			display: grid;
			grid-template-columns: minmax(200px, 1fr) 4fr;
			justify-self: stretch;
			align-items: stretch;
			border-bottom: 1px solid #d9dcde;
		}
	}
	.main-cell{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap:5px;
		padding-left: 10px;
		padding-right: 10px;
		border-right: 1px solid #d9dcde;
		i{
			margin-right: 10px;
		}
	}
	.section{
		font-weight: bold;
	}
	.subsection{
		padding-left: 32.25px;
	}
	.grouped{
		background-color: rgba(0, 0, 0, 0.02);
	}
	.month-cells{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
		span{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.month-cell{
		border-right: 1px solid #d9dcde;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
	}
	.month-cell-clickable:hover{
		cursor: pointer;
		border: 1px solid #3498db;
	}
	.collapser{
		cursor: pointer;
	}
	.collapser:hover{
		color: #3498db;
	}
	.progression-dot{
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin: 0 2px;
	}
	.progression-dot-grey{
		background-color: #d2d5dd;
	}
	.progression-dot-red{
		background-color: rgba(255, 0, 0, 0.5);
	}
	.progression-dot-orange{
		background-color: rgba(255, 165, 0, 0.5);
	}
	.progression-dot-green{
		background-color: rgba(0, 128, 0, 0.5);
	}
}
