
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
.content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
