
$menu-bg-color: #1F2937;
.auth{
    height:100vh;
    background-color:$menu-bg-color;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.auth-content{
    height:230px;
    width:480px;
    background-color:white;
    border-radius:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:15px;
}
.auth-logo{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom:15px;
}
.auth-info{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.title{
    font-size:24px !important;
}
.subtitle{
    font-size:14px !important;
}
.loader {
    animation: pulse 2s linear infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.90);
    }
    70% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.90);
    }
}
@media only screen and (max-width:1200px){
    .auth-content{
        height:100vh;
        width: 100vw;
    }
    
    .auth-info{
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .title{
        font-size:24px !important;
        text-align: center;
    }
    .subtitle{
        font-size:14px !important;
        text-align: center;
    }
}
