
span{
	/*margin-left: 5px;*/
	vertical-align: middle;
	font-size: 11px;
	display: inline-block;
	line-height: 1;
	padding: .5833em .833em;
	font-weight: 700;
	border: 0 solid transparent;
    border-radius: .28571429rem;
}
.prevue{
	color: white;
	border-color: #2185d0;
	background-color: #2185d0;
}
.retard{
	border-color: red;
	background-color: red;
}
.realisee{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
.annulee{
	color: white;
	border-color: #a5673f;
	background-color: #a5673f;
}
