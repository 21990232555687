
.reponse-consommation{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	padding: 10px 0;
	border-bottom: 1px solid #d7d7d9;
	.text-reponse{
		color: #74767a;
		font-size: 12px;
	}
	.input-reponse{

	}
}
