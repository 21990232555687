
.vg-box-info{
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    font-size: 11px;
    padding:12px 10px;
    .libelle{
        color:lightgray;
        height: 20px;
    }
    .info{

    }
}
