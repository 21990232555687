
.vg-affectables-selector{
    width: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    .affectable-collapser{
        border:1px solid #dcdfe6;
        width:100%;
        height:36px;
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding:0 10px;
        cursor:pointer;
    }
    .selected-affectes{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        .selected-affecte{
            margin:2px 5px 2px 0;
            padding:5px;
            border:1px solid #dcdfe6;
            border-radius: 1px;
        }
        .selected-affecte:nth-child(n+3){
            display: none;
        }
    }
    .selected-affecte-more{
        margin:2px 5px 2px 0;
        padding:5px;
        border-radius: 3px;
        background-color: #0099ff;
        font-weight: bold;
        color:white;
    }
}
.affectable{
    padding:10px;
    cursor:pointer;
    background-color: white;
}
.affectable:hover{
    background-color: whitesmoke;
}
.affectables-datalist{
    max-height:300px;
    width:100%;
    overflow:auto;
    padding:10px;
    .search{
        margin-top: 5px;
    }
}
.only-desktop{
    display: inherit;
}
.only-mobile{
    display: none;
}
@media only screen and (max-width: 768px) {
    .only-desktop{
        display: none;
    }
    .only-mobile{
        display: block;
    }
    .selected-affectes{
        .selected-affecte:nth-child(n+2){
            display: none;
        }
    }
}
