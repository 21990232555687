
span{
	margin-left: 5px;
	vertical-align: middle;
	font-size: 11px;
	display: inline-block;
	line-height: 1;
	padding: .5833em .833em;
	font-weight: 700;
	border: 0 solid transparent;
    border-radius: .28571429rem;
}
.en_cours{
	color: white;
	border-color: #f5504a;
	background-color: #f5504a;
}
.resolue{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
.en_attente{
	color: white;
	border-color: #a5673f;
	background-color: #a5673f;
}
.prise_en_compte{
	color: white;
	border-color: #2185d0;
	background-color: #2185d0;
}
.titlehover:hover{
	opacity: 0.8;
}
.a_valider{
	color: white;
	border-color: #53b84f;
	background-color: #53b84f;
}
