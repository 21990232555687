
.vg-fichesav-table{
	height: 90%;
	overflow-y: auto;
}
.cards-container{
	width: 100%;
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	>div{
		width: 100%;
		border: 1px solid whitesmoke;
		padding: 5px;
		cursor: pointer;
	}
	.card-maintenance:hover{
		border-left: 4px solid whitesmoke;
	}
	.card-maintenance-selected{
		border: 1px solid #059cff;
	}
	.card-maintenance-selected:hover{
		border-left: 4px solid #059cff;
	}
}
