
.fm-cell-render{
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .vg-lieu-path-viewer{
                width: 40vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .content{

    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        gap: 5px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
        }
    }
}
