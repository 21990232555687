
.vg-selector-categorie-option{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 10px;
}
@media only screen and (max-width:1200px){
    .vg-selector-categorie-option{
        justify-content: flex-start !important;
    }
}
