

.equipement-card-ZoomDashboard{
    color:#a2a2a2;
    font-size: 11px;
    .info-equip{
        display: flex;
        margin-bottom: 5px;
        height: 30px;
        img{
            align-self: center;
            margin-right: 5px;
        }
        span{
            align-self: center;

        }
    }
    .txt-important{
        font-size: 13px;
        color: black;
    }
    .info-cellrender{
        height: 18px;
        width:100%;
        .left{
            float: left;
            width: 50%;
        }
        .right{
            float: right;


        }
    }

}
@media (min-width: 768px){
    .equipement-card-ZoomDashboard{
        font-size: 13px;
        > div {
            line-height: 18px;
        }
        .txt-important{
            font-size: 15px;
        }
    }
}
