
#ifc-viewer{
    height: 100%;
    width: 100%;
}
.ifcEntity{
        cursor: pointer;
    }
    .ifcEntity:hover{
        text-decoration: underline;
    }
  .badge-75 {
    --badge-size: 75px;
  }

  .badge-200 {
    --badge-size: 200px;
  }

  .badge {
    /* Un rond vert */
    height: var(--badge-size);
    width: var(--badge-size);
    border-radius: 50%;
    background-color: limegreen;
    border: calc(var(--badge-size) / 10) solid lightgray;
    /* Des initiales blanches */
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: calc(var(--badge-size) / 1.75);
    font-weight: bold;
    /* Et centrées */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
