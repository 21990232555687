
.vg-equipement-select{
    height: 100%;

    .tableau{
        height: 90%;
    }
}
.select-equipement-template{
    display: flex;
    flex-direction: column;
    .info{
        display: flex;
        flex-direction: row;
        margin-bottom:10px;
        .name{
            width: 80%
        }
        .icon{
            width: 20%;
        }
    }
    .info-supp{
        flex-direction: row;
        display: none;
        .path{
            width: 50%;
        }
        .qr-code{
            width: 50%;
            text-align: right;
        }
    }

    &:hover{
        .info-supp{
            display: flex;
        }
    }
}
