
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
}
.label-file:hover {
    color: #535353;
}

// et on masque le input
.input-file {
    display: none;
}
