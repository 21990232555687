
.vg-equipements-pieces-taches-actives-table{
	height: inherit;
    overflow-y: auto;
    width: 100%;
	.item-to-check{
		width: 100%;
		border-bottom: 1px solid #d7d7d9;
	}
}
