
.vg-bon-de-commande-items-form-livraison{
    .bc-item-row{
        display: grid;
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 60px;
        justify-items: center;
    }
    .header{
        font-weight: bold;
        margin-bottom:5px;
        font-size: 11px;
        color: #9e9da9;
    }
}
.vg-bon-de-commande-items-form-non-livraison{
    .bc-item-row{
        display: grid;
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr 60px;
        justify-items: center;
    }
    .header{
        font-weight: bold;
        margin-bottom:5px;
        font-size: 11px;
        color: #9e9da9;
    }
}
.bc-item-footer{
    width:100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}
.item-unite{
    color: #9e9da9;
}
