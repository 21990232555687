
    .secondary_section_title{
        color: #35b1ea;
        font-size: 16px;
    }
    .no-margin{
        margin: 5px !important;
    }
    table,
td {
    border: 1px solid #333;
    padding:5px;
}
th{
    padding:5px;
}
thead,
tfoot {
    background-color: #e8eaee;
    color: #484848;
}

