
.marginUnderInput{
    margin-bottom:20px
}
#action-buttons > .vg-button{
    margin-bottom:15px;
}
#libelle-categorie-selectionne{
    width: 50%;
    background-color: #f2f2f2;
    border: 1px solid #d6d1ce;
    padding: 4px 10px;
    font-weight: bold;
}
#last-five-categories-bloc{
    display: flex;
    flex-direction: column;
    align-items: center;
}
