
.vg-filter-site-alternative{
	.header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom:5px;
		border-bottom: 1px solid #c7c7c7;
		color: #8d8d8d;
	}
	.content{
		height: 200px;
		overflow: auto;
		.site-select{
			margin: 5px 0;
			padding: 5px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: whitesmoke;
			.libel-site{
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			.site-close{
				cursor: pointer;
				margin-right: 10px;
			}
			/*height: 35px;
		    display: flex;
		    align-items: center;
			justify-content: space-between;
		    border: 1px solid #c4c4c4;
		    margin-bottom: 5px;
		    margin-top: 5px;
		    padding: 5px;
		    background-color: #f2f2f2;
			.libel-site{
				font-size: 11px;
				img{
					margin: 0;
					margin-right: 5px;
				}
			}
			.site-close{
				cursor: pointer;
				margin-right: 2px;
			}*/
		}
	}
}
.content-header-modal{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom:5px;
	border-bottom: 1px solid #c7c7c7;
	color: #8d8d8d;
	.options-filtrage{
		display: flex;
		justify-content: flex-end;
	}
}
.content-body-modal{
	height: 300px;
	overflow: auto;
	padding: 5px 0;
}
