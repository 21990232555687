
.todo-row{

    display:flex;
    justify-content: space-between;
    padding:10px 5px;
    border-bottom:1px solid #d9d9d9;
    width:100%;
}
.todo-row:hover{
    background-color:#a2c2ff;
    color:white;
    cursor:pointer;
}
