
.body-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.body-content-validation{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sortie-equipement-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #a782b2;
    }
}
