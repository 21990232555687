
.vg-pagination{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    /*i{
        font-size:24px;
        color:#2eaac7
    }*/
    .contener-pagination{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .vg-button{
            margin:0 10px;
        }
    }
    .infos-pagination{
        font-size: 10px;
        color: rgba(0,0,0,0.7);
    }
}
.button-paginator {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 1000;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    color:#2eaac7
}
.button-paginator[class="button-paginator hoverable"]:hover{
  background-color: #e5f5f8;
  border: 1px solid #2eaac7;
}
.selected{
    background-color: #e5f5f8;
    border: 1px solid #2eaac7;
}
.button-paginator[class="button-paginator hoverable focusable"]:focus{
    background-color: #e5f5f8;
    border: 1px solid #2eaac7;
}

.vspButtonFast{
  background-color: #f4f8fb;
  color: #b5b9bc;
}
