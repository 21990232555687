
.vc-border{
    border: none !important;
}
.vg-calendar-picker{
    .vc-title{
        font-family: Verdana,sans-serif;
        font-size: 14px;
    }
    .vc-weekday{
        color: black;
        font-size: 12px;
    }
}
@media screen and (max-width: 600px) {
    .vc-container{
        --day-content-width: 40px !important;
        --day-content-height: 40px !important;
        --weeks-padding: 0px !important;
    }
    .vc-weekday{
        color: #b7b7b7 !important;
    }
    .vc-grid-cell{
        border-bottom: 1px whitesmoke solid !important;
    }
    .vc-rounded-full{
        border-radius: 3px !important;
    }
    .vc-dots{
        padding-bottom: 1px !important;
    }
}
