
.vg-consommable-selector-cellrender{
    display: flex;
    align-items: center;
}
.vg-consommable-selector{
    height: 100%;
    .tableau{
        height: 300px;
    }
}
