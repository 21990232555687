
.vg-icon{
    display: block;
}
.svg-icon-18{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 18px;
    height: 18px;
}
.svg-icon-20{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.svg-icon-25{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}
.svg-icon-30{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 30px;
    height: 30px;
}
.svg-icon-xxs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 12px;
    height: 12px;
}
.svg-icon-xs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 15px;
    height: 15px;
}
.svg-icon-md{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.svg-icon-lg{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}
.png-icon-xs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 15px;
    height: 15px;
}
.png-icon-md{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.png-icon-lg{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotate 0.8s linear infinite;
}
