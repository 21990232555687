
.pathViewerBloc{
    display:flex;
    flex-direction:column;
}
.path{
    font-size: 11px;
    color:#9e9da9;
}
.path-cell-render{
	&.vg-lieu-path-viewer{
		.path{
			font-size: 12px;
			color:#888888;
		}
		.piece{
			font-size: 14px;
		}
	}
}
@media screen and (min-width: 600px) {
    .lineHeight{
        line-height:15px !important;
    }
}
@media screen and (max-width: 600px) {

}
