
.link{
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
}
.prestataire{
    color:#409eff;
    font-size:14px;
    text-decoration:underline;
    cursor:pointer;
    font-weight:bold;
}
.cout{
    color:#409eff;
    font-size:14px;
    font-weight:bold;
    padding-left:0px;
}
.rate{
    color:#409eff;
    font-size:11px;
    font-weight:bold;
    padding-left:0px;
}
.user-name{
    font-weight: bold;
}
.subtitle{
    color:#409eff;
    font-size:11px;
}
.subsubtitle{
    color:#7f7f7f;
    font-size:11px;
}
