
.equipement-check-lieu-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
    width: inherit;
    padding: 10px 0;
    .reponse{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        color: #74767a !important;
        .icon{
				width:3px;
				height: 14px;
				background-color: rgb(243, 111, 58);
			}
        .name{
            font-weight: normal !important;
        }
    }
	img{
		width:20px;
		height:20px;
	}
}
