
.vg-equipement-viewer{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.img-equipement{
		img{
			width:25px;
			height:25px;
			margin-right: 10px;
		}
	}
	.info-equipement{
		display: flex;
	    flex-direction: column;
		.name{
			font-weight: bold;
		}
		.qrcode{
			font-size: 12px;
		}
	}

}
