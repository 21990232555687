
.rowProblem{
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px darkgrey;
    margin-bottom: 5px;
    align-items: center;
    padding: 3px;
}
.disabledUi{
   opacity:0.3;
}

