
.VgChips{
    .vg-chips{
        float: left;
        height: 35px;
        padding: 8px;
        border: 1px solid #0066FF;
        font-size: 13px;
        // border-radius: 5px;
        margin-right: 15px;
        .vg-chips-key{
            color: #0099FF;
            font-weight: bold;
        }
        .vg-chips-value{
            color:#7d7d7d;
        }
    }
    .chips-close{
        float: right;
        cursor: pointer;
        margin-left: 15px;
        margin-top: -2px;
        img{
            height: 10px !important;
        }
    }
}
