
.detail-fichesav-modal{
    .fichesav-info{
        display: flex;
        > div{
            margin-bottom: 10px;
            &:first-child {
                color: lightgray;
                width: 40%;
            }
        }
    }
}
.plan-intervention{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;
}
.send-email-section{
    margin: 10px 35px;
}
@media only screen and (max-width: 1200px){
    .plan-intervention{
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items:center;
    }
    .send-email-section{
        margin: 10px 0;
    }
}
.closebtn-fichesav{
    display: flex;
    justify-content: center;
}

.fichesav-description{
    margin-bottom: 15px;
    font-size: 16px;
    .vg-fichesav-description{
        display: inline;
    }
}
.body-full-height{
    /*height:100vh !important;*/
}
.body-height{
    /*height: auto !important;*/
}
