
.lieu-demande-intervention-cell-render{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    padding-left: 0;
    border-bottom: 1px solid #DCDFE6;
    cursor: pointer;
    >i{
        margin-right: 20px;
    }
}
