
.equipement-fm-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
		margin-right:10px;
		vertical-align: inherit;
		width: 22px;
		height: 22px;
	}
}
