
.composantmobilecellrender{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    .type,.name{
        width: 50%;
        display: flex;
        img{
            margin:0;
            margin-right: 10px
        }
        justify-content: center;
    }
}
