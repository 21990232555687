
.consommable-cellrenderer{
    height: 100%;
    padding:5px;
    border-bottom: 1px solid #cccccc;
    .row-1{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .row-2{
        font-size: 13px;
        .label-row{
            color:#838383;
        }
        .data-row{
            color:#000000;
            font-weight: bold;

        }
    }
    .files-image{
        float: right;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .files-only-image{
        // juste pour supprimer le texte "photo" pour avoir juste le bouton avec la photo
        margin-right: 20px;
        label{
            span{
                display: none !important;
            }
        }
    }
}
