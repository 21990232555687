
.vg-categorie-composants-update{
	border: 1px solid #dcdfe6;
	width: 100%;
	height: 100%;
	.checkboxComposant{
		margin:0 !important;
		>div{
			display: flex;
			width:100%;
		}
		img{
			margin-right: 10px;
		}
		.no-photo{
			width: 20px;
			height: 20px;
			margin-right: 10px;

		}
		.libelCompo{
			width: calc(75% - 40px);
		}
		.typeCompo{
			color: #999b9e;
			overflow: hidden;
			margin-right: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 25%;
		}
	}
	>.composants{
        width: 100%;
    	height: 85%;
    	overflow: auto;
		padding: 10px;
		font-size: 10px;
		>div{
			display: flex;
			height: 50px;
			justify-content: flex-start;
			align-items: center;

		}
	}
	>.none{
		display: flex;
		justify-content: center;
	}

}
