

#viewer-maintenance{
    .label{
        color: #BBBCBE;
        font-size: 14px;
        text-align: right;
        width: 40%;
    }
    .field{
        margin-bottom:15px;
    }

    .equipement-data{
        div{
            margin-top: 8px;
        }
        .name-equipement{
            font-size: 17px;
        }
        .icon-composant{
            display: block;
            margin-top:3px;
        }
    }

}
#viewer-maintenance-mobile{
    .vg-flex{
        display: flex;
        .vg-fichesav-origin{
            margin-right: 7px;
        }
    }
    .vg-fichesav-description{
        white-space: normal;
        display: inline;

    }
    .header-maintenance{
        margin-bottom: 15px;
        padding:0 20px;
        font-size: 1.2em;
    }
}
