.tooltip-directive {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltip-directive .tooltip-directive-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 2px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 9999 !important;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-directive:hover .tooltip-directive-text {
  visibility: visible;
}
