
    .tag-grid{
        .ag-grid{
            height: 100%;
            .ag-root-wrapper{
                min-height: 200px;
            }
        }
        width: 100%;
    }
    @media only screen and (max-width: 768px){
        .tag-grid{
            .ag-grid{
                /*height: 90%;*/
                .ag-root-wrapper{
                    min-height: 100px;
                }
            }
        }
    }
    .ag-theme-balham .ag-cell-inline-editing{
        height:100% !important;
        border-radius:0px !important;
    }
    .ag-theme-balham .ag-cell-inline-editing select{
        height:100% !important;
        width:100% !important;
        border-radius:0px;
    }
    .ag-theme-balham .ag-cell-inline-editing input{
		height:100% !important;
        width:100% !important;
        border-radius:0px;
    }

    .vg-desktop-tag-grid{
        height:90%;
    }

	/* CUSTOMIZE row focus */
	.ag-row-focus:not(.ag-row-selected){
		background-color: #ebf3f9 !important;
		border-style: none;
		/*-webkit-user-select:text; -moz-user-select:text; -ms-user-select:text; user-select:text;*/
	}
    /* CUSTOMIZE row focus */
	.ag-row-focus{
		z-index: 0 !important;
	}
    .ag-theme-balham .ag-header-cell-resize{
        z-index: 0 !important;
    }

	.ag-theme-balham .ag-cell .vg-synthese-cell{
		line-height: normal !important;
	}
  .chbox-TagGrid{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    .el-checkbox__label{
      font-size: 15px !important;
      font-family: Open sans;
      font-weight: 400;
    }
    .el-checkbox__inner{
      width: 17px;
      height: 17px;
      margin-right: 15px;
    }
    .el-checkbox__inner::after{
      height: 8px;
      left: 5px;
    }
  }


.overlay-loading-template{
    background-color:#35b1ea;
    color:white;
    border:none;
    padding:10px;
    font-size:14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.overlay-no-rows-template{
    background-color:white;
    color:#35b1ea;
    border:none;
    font-size:14px;
}

