
.schedule-item{

}
.tui-full-calendar-popup-detail{
    z-index: 1003;
}
.tui-full-calendar-popup-container{
    padding:0;
    &.red{
        border-top: 10px solid #f5504a;
    }
    &.green{
        border-top: 10px solid #53b84f;
    }
    &.blue{
        border-top: 10px solid #A6C5EB;
    }

    .tui-full-calendar-section-header{
        padding-left:10%;
        padding-top: 10%;
    }
    .tui-full-calendar-section-detail{
        padding:0 10%;
    }
    .popup-title{
        display: flex;
    }
    .header-tui{
        font-weight: bold;
        width: 80%;
        font-size: 12px;
        word-break: break-all;
        &.notBold{
            font-weight: normal;
        }
        &.verification{
            display: flex;
            .space{
                width: 22%;
            }
            .header-title{
                width: 78%;
                word-break: break-word
            }
        }
        .vg-fichesav-description{
            word-break: break-word;
        }
    }
    .popup-close-modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
    }
    .footer{
        background-color: #d7d7d7;
        height: 50px;
    }
}
.popup-detail-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 200px;
    overflow-y: auto;
    >.schedule-item{
        border: 1px solid #888888;
        margin-bottom: 5px;
        padding: 5px;
    }
}
.icon{
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.label-fm{
    color: #a4a4a4;
}
.close{
    float: right;
    cursor: pointer;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
