
.vg-modal-body-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .modal-body-container-left{
        flex-grow: 1;
        padding-right: 20px;
    }
    .modal-body-container-right{
        flex-grow: 1;
        padding-left: 20px;
        border-left: 1px solid #f2f2f2;
    }
}

@media only screen and (max-width:800px){
    .vg-modal-body-container{
        flex-wrap:wrap;
    }
}

@media only screen and (max-width:1200px){
    .vg-modal-body-container{
        display: block;
        .modal-body-container-left{
            padding-right: 0 !important;
            margin-bottom: 20px;
        }
        .modal-body-container-right{
            padding-left: 0 !important;
            border: none !important;
        }
    }
}
