
.step{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	.title{

	}
}
