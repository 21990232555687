
#InventaireCellRender{
}
.inventaireLieuCellRenderB{
    .secondary-text{
        font-size: 10px;
        color: black;
        font-weight: bold;
        text-transform: uppercase;

    }
    .row_one{
        display:flex;
        flex-direction:row;
        padding:5px;
        .col_one{
            order:1;
            flex-grow:2;
            margin-top: 5px;

            display:flex;
            flex-direction:column;
            .nomequip{
                font-size: 14px;
                font-weight: bold;
                flex-grow:2;
                white-space: normal;
                margin-bottom: 2px;

            }
            .vlge{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
            }
            .date{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
            }
            img{
                flex-grow:3;
                align-self:flex-start;
                margin-bottom: 5px;
                height: 25px;
            }
        }
        .col_two{
            display:flex;
            flex-direction:column;
            order:2;
            flex-grow:1;
            // margin-top: 10px;
            margin-left: 10px;
            font-size: 18px;
            .badge-missing{
                width: 80px;
                flex-grow: 2;
                align-self: flex-end;
                font-size: 12px;
                color: white;
                > div{
                    text-align: center;
                    padding: 5%;
                }
                .green-badge{
                    background-color: #60BB46;
                }
                .red-badge{
                    background-color: #e60000;
                }
                .empty-badge{
                    // color:grey;
                    background-color: #a2a2a2;
                }
            }
            .mouv{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:0;
                align-self: flex-end;

            }
            .etat{
                font-size: 10px;
                color: #b0b0b0;
                flex-grow:1;
                align-self: flex-end;

            }
        }
    }


}
