
.vg-week-selector{
	width: 180px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	>span{
		font-size: 11px;
		margin-top: 5px;
	}
}
