

#vg-contrat-title{
    /*background-color: rgba(105, 76, 167, 0.1);*/
    border: 1px solid #429fff;
    color: #429fff;
    border-radius: 3px;
    font-size: 28px;
    padding: 0px 6px;

}


.vg-contrat-section-title{
    color:#429fff;
    font-size:15px;
}
.vg-contrat-section-title-disabled{
    color:grey;
    font-size:15px;
    opacity:0.3;
}
.delete_action{
    color:#e53530;
    font-size:18px;
}

