
.uploader-container{
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;

	input[type="file"] {
	    display: none;
	}
	.tag-selector{
		width: 50%;
	}
	.upload-photo{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: baseline;
		border: 1px solid #ccc;
		padding: 6px 12px;
		cursor: pointer;
		>span{
			padding-left: 5px;
		}
	}
	.select-tag{
		width: 50%;
	}
	.upload-file{
		border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
		width: 50%;
	}
	.upload-disabled{
		cursor: not-allowed;
	}
	.upload-button{
		padding: 2px 10px;
		cursor: pointer;
		border: 1px solid #ccc;
	}
	.upload-button:not(.upload-disabled):hover{
		box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
	}
	.upload-button:not(.upload-disabled):active,.upload-button:not(.upload-disabled):focus{
	  box-shadow: 0 1px #00cc66;
	}
}
li:hover {
  background-color: #e6effb;
  cursor: pointer;
}
.vg-upload-list {
	width: 100%;
  border: 1px solid lightgray;
  margin: 0;
  padding: 10px;
  list-style: none;
}
.vg-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 14px;
  color: #606266;
  line-height: 1.8;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  .contener-click{
	  display: flex;
	  align-items: center;
	  width: 95%;
	  .file-name{
      	width: 50%;
      	display: grid;
		margin-right: 5px;

      }
      .file-tag{
      	width: 44%;
		color: #429fff
      }
      i{
    	  width: 6%;
      }
  }

  .item-status {
	  color: green;
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

}
// .vg-upload-list__item:first-child {
//   margin-top: 10px;
// }
.vg-upload-list__item .vg-icon-close {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  opacity: 0.75;
  color: #606266;
}
.vg-upload-list__item .vg-icon-close:hover {
  opacity: 1;
}
.vg-upload-list__item:hover .vg-icon-close {
  display: inline-block;
}
.vg-icon-close-tip {
  display: none;
}


.vg-upload-list__item-status-labvg-remove {
  position: absolute;
  color: red;
  right: 5px;
  top: 0;
  line-height: inherit;
}
/* .item-status:hover {
  opacity: 0.5;
} */
.vg-upload-list__item-status-labvg-remove:hover {
  opacity: 0.5;
}
.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
}
.icon-lock {
  color: red;
}
.lock:hover .icon-lock {
  display: inline;
}
.vg-upload-list vg-upload-list--text a:hover {
  color: blue;
}
#vg-uploader .vg-upload__tip {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.tx-uploader-show {
  color: grey;
  margin-right: 2px;
}
.tx-uploader-show:hover {
  color: #2091cf;
}
