
.vg-date-picker{
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: #9e9da9;
        font-size: 0.85em;
    }
}
.date-picker-start , .date-picker-end{
    text-align: center;
    color: #252525;
    font-family: "Open Sans";
    width: 45%;
}
@media only screen and (max-width: 768px) {
    .vg-date-picker{
        flex-direction: column;
        input{
            width: 100%;
        }
        span{
            display: block;
            height: 20px;
            width: 100%;
            text-align: center;
            margin: 3px 0;
        }
    }
}
