
.vg-search-datas-viewer{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.section-header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
		border-bottom: 1px solid #e5e7eb;
		background-color: #F9FAFB;
		span{
			
			color: #4B5563;
		}
		small{
			
			color: #6B7280;
		}
		.section-title{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5px;
		}
	}
	.viewer-container{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 5px;
		font-family: ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
		.viewer-item{
			cursor: pointer;
			width: 100%;
			padding: 8px 5px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 5px;
			font-size: 12px;
			.item{
				width:100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 5px;
				.item-content{
					flex-grow: 5;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					gap: 5px;
					.title{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						gap: 3px;
						font-weight: bold;
						color: rgb(17 24 39/var(--tw-text-opacity));
						img{
							width: 20px;
							height: 20px;
							margin-right:4px;
						}
					}
					.subtitle{
						font-size: 12px;
						color: #6B7280;
					}
				}
				.item-icon{
					width: 30px;
					flex-grow: 1;
					color: #6B7280;
					font-weight: normal;
				}
			}
			.btns{
				width:100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 15px;
				padding-left: 15px;
				background-color: rgb(249 250 251);
				border: 1px solid #e5e7eb;
			}
		}
		.viewer-item-lg{
			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 5px;
			font-size: 14px;
			.item{
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;
				img{
					width: 25px;
					height: 25px;
				}
			}
			.btns{
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 20px;
			}
		}
		.disabled{
			cursor: default !important;
			color: #ccc !important;
		}
		.disabled:hover{
			background-color: white !important;
		}
		.viewer-item:hover{
			background-color: whitesmoke;
		}
	}
}
