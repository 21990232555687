
.equipement-form-inline-inputs{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
    .libelle-categorie-selectionne{
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        background-color: #d8d9da;
        padding: 6px 10px;
        font-weight: bold;
        color: black;
        font-size:12px;
        white-space: nowrap;
    }
    span{
        margin: auto 0;
    }
}

.title-section{
    color: #35b1ea;
    font-size: 16px;
    margin-bottom: 10px;
}
