
.vg-timeline{
    width: 100%;
    height: 100%;
    overflow: auto;
    .vg-timeline-container{
        height: 100%;
        width:100%;
        overflow: auto;
        .vg-timeline-item{
            border-bottom: 1px solid whitesmoke;
            padding: 10px 0;
        }
    }
    .vg-timeline-container-info{
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #35b1ea;
    }
    /*.classement{
        margin: 5px 0;
        width: 100%;
        .classement-label{
            background-color: #eee;
            font-size: 0.85em;
            font-weight: bold;
            display: flex;
            justify-content: center;
        }
        .classement-group{
            margin: 5px 0;
        }
    }*/

}

