
.vg-signature-pad-container{
    width: 100%;
    height: 100%;
    #vg-signature-pad{
        border: 1px solid #ccc;
    }
    .footer{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
}
// media queries mobile
@media only screen and (max-width: 1200px){
    .vg-signature-pad-container{
        #vg-signature-pad{
            width: -webkit-fill-available !important;
        }
    }
}
