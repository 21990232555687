
.vg-plan-interactif-panel{
    height: 100%;
    width: 100%;
    .main-container{
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding: 10px 0;
    }
    .info-drag-and-drop{
        color: #02a7f0;
        font-size: 11px;
    }
}
