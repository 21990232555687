
.vg-statistiques-maintenance-dashboard{
    display: flex;
    justify-content: flex-start;
    justify-content: space-around;
    height: 100%;
    .charts-left-panel{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 20%;
        height: 100%;
        border-right: 1px solid #ccc;
        padding:0 20px;
        .chart-legend{
    		.chart-legend-item{
    			display: flex;
    			justify-content: flex-start;
    			align-items: center;
    			.rect{
    				width: 24px;
    				height: 12px;
    				margin-right: 10px;
    			}
    		}
    	}
    }
    .charts-container{
		width: 80%;
		min-width: 400px;
		height: 100%;
		align-items: center;
        .chart-section{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.chart-bar{
				width: 100%;
                padding: 40px;
			}
            .chart-selector{
                width: 200px;
    			text-align: center;
    		}
		}
	}
    .width-100-percent{
        width: 100% !important;
    }
}
