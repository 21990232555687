

.step{
	background-color: #feca1b;
	padding: 0px 8px;
}
.step-modal-body{
	max-height: 60vh;
	overflow: auto;
}
