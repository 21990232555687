

$border-color: #d3d7dd;
$header-background-color: #f7f7f9;
$header-text-color: #505967;
$header-border-bottom-color: #e1e2e4;
$graph-bar-fill-color: #87b0dd;
$graph-text-color: #9496a1;

.vg-dashboard-curatif-rapport{
	border: 1px solid $border-color;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: inherit;
	.header{
		background-color: $header-background-color;
		border-bottom: 1px solid $header-border-bottom-color;
		color: $header-text-color;
		font-size: 12px;
		font-family: Helvetica Neue Light, Open Sans;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 5px 10px;
		min-height:40px;
		height: auto;
		.title{
			font-weight: bold;
		}
		.header-right{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.pagination{
				margin-right: 10px;
			}
			.shortcuts{
				min-width: 180px;
			}
		}
	}

	.body{
		padding: 15px;
		width: 100%;
		height: 100%;
		>div{
			width: 100%;
			height: 100%;
		}
	}

	.footer{
		padding: 0px 5px 10px 15px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media screen and (max-width: 991.98px) {
	.vg-dashboard-curatif-rapport{
		.header{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			min-height: inherit;
			.header-right{
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.pagination{
					margin-right: 10px;
				}
				.shortcuts{
					min-width: 180px;
				}
			}
		}
	}
}
