
.intervention-nb-documents-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}
.existed-documents {
    color: #35b1EA;
    cursor: pointer;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.no-documents {
    color: red;
    cursor: pointer;
    display: inline-block;
    animation: pulse 1.5s infinite;
}
