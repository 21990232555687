
.assets-reporting-verification{
    padding:0 40px;
    height: 100%;
    .separateur{
        height: 2px;
        width: 100%;
        background-color: #385FAF;
        margin-bottom: 100px;
    }
}
.body-rapport{
    height: calc(100% - 200px);
}
.header-rapport{
    width: 100%;
    height: 50px;
    margin-bottom: 7px;
    margin-top: 30px;
    display: flex;
    .entete-lieux{
        flex-grow: 1;
        align-self:center;
        font-size: 20px;
    }
    .entete-title{
        text-align: center;
        flex-grow: 2;
        align-self:center;
        font-size: 20px;
        .titlehead{
            font-size: 24px;
        }
    }
    .entete-img{
        float: right;
        flex-grow: 1;
        img{
            float: right;
            margin-right: 30px;
        }
    }

}


@media print{
    .assets-reporting-verification{
        padding:10px;
        &.vg-print-show{
            visibility: visible !important;
        }
        .vg-print-show{
            visibility: visible !important;
        }
    }
}
