
.schedule-template{
    padding: 0;
    height: 100%;
    font-size: 11px;
    color: white;
    .schedule-title{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 2px;
        overflow: hidden;
        flex-wrap: nowrap;
        width: 100%;
        height:100%;
        font-weight: normal;
        font-size: 10px;
        .title-container{
            line-height: normal;
            padding: 5px;
        }
        .icon-container{
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 5px;
            .icon{
                width: 12px;
                height: 12px;
                filter: brightness(0) invert(1);
            }
        }
    }
}
.schedule-template-maintenance-ouverture{
    background-color: #3095C9 !important;
}
.schedule-template-maintenance-fermeture{
    background-color: #3095C9 !important;
    
}
.schedule-template-maintenance-affectation{
    background-color: #3095C9 !important;
    
}
.schedule-template-intervention-programmee{
    background-color: #908070 !important;
    
}
.schedule-template-tache-session{
    background-color: #4F56A6 !important;
    
}
$border-left-width: 4px;
.maintenance-open{
    border-left: $border-left-width solid red;
}
.maintenance-close{
    border-left: $border-left-width solid #3baa36;
}
.schedule-tache-session-bleu{
    border-left: $border-left-width solid #4F56A6;
}
.schedule-tache-session-red{
    border-left: $border-left-width solid red;
}
.schedule-tache-session-vert{
    border-left: $border-left-width solid #3baa36;
}
.schedule-intervention-prevue{
    border-left: $border-left-width solid red;
}
.schedule-intervention-realisee{
    border-left: $border-left-width solid #3baa36;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.intervention-warning-aucun-document{
    position: absolute;
    right: 5px;
    top: 5px;
    height:5px;
    width:5px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
}
@media only screen and (max-width:800px){
    .schedule-template-week{
        .not-responsive{
            display: none !important;
        }
        .text-ellipsis{
            display: none !important;
        }
        .schedule-title>span{
            display: none !important;
        }
        .schedule>span{
            display: none !important;
        }
    }
}
