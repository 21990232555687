
.consommable{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid whitesmoke;
	cursor:pointer;
	.consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommable-stock{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
.consommable:hover{
	font-weight: bold;
}
.consommation{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid whitesmoke;
	.consommable-details{
		.title{
			color: #35b1ea;
		}
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.consommation-details{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
}
