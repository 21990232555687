
$tag-bg-color: #c9ecfc;
$tag-color: #7F7F7F;
$nb-equipements-color: #6f2881;
$nb-equipements-bg-color: #d5b3d3;
$rond-famille-default-color: #d5b3d3;
$rond-famille-height: 16px;
$level-icon-height: 16px;
$tag-img-height: 12px;
$tag-padding-top-bottom: 4px;
.vg-tree-node-lieu{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:10px;
	padding: 8px 8px;
	.main{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		.rond-famille{
			width: $rond-famille-height;
			height: $rond-famille-height;
			>div{
				width: $rond-famille-height;
				height: $rond-famille-height;
				background-color: $rond-famille-default-color;
				border-radius: 50%;
			}
		}
		.level-icon{
			width: $level-icon-height;
			height: $level-icon-height;
		}
		.tooltip-nb-equipements{
			padding: 5px;
			font-weight: bold;
			font-size: 11px;
			color: $nb-equipements-color;
			background-color: $nb-equipements-bg-color;
			border-radius: 100%;
		}
	}
	.complement{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		.famille-selector{
			min-width:200px;
		}
		>.tag{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			background-color: $tag-bg-color;
			padding: $tag-padding-top-bottom 10px;
			/*margin-right: 5px;*/
			border-radius: 2px;
			color: $tag-color;
			>img{
				width: $tag-img-height;
				height: $tag-img-height;
			}
		}
		>.badge-pi{
			padding: 3px;
			background-color: red;
			color: white;
			font-size: 10px;
			border-radius: 2px;
			white-space: nowrap;
		}
	}
}
.tree-node-generique{
	filter: grayscale(80%);
}
@media only screen and (max-width: 1200px){
	.vg-tree-node-lieu{
		gap:5px;
		font-size: 11px !important;
		padding: 0 0 0 5px;
		width: 100%;
		.main{
			gap: 5px;
			.level-icon{
				width: $level-icon-height;
				height: $level-icon-height;
			}
			.rond-famille{
				height: 10px;
				width: 10px;
				border-radius: 0;
				>div{
					height: 10px;
					width: 10px;
				}
			}
		}
		.complement{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 2px;
			overflow-y: auto;
			.famille-selector{
				min-width:150px;
			}
			.tag{
				padding: $tag-padding-top-bottom 5px;
				img{
					width: $tag-img-height;
					height: $tag-img-height;
				}
			}
			.only-desktop{
				display: none;
			}
		}
	}
}
