
.footer-btn-open-scanner{
    height: 100%;
    width: 100%;
    margin-right: 0;
    opacity: 1 !important;
    background-color: #059cff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    >i{
        margin-right: 10px;
    }
}
