
.select-tableau-view{
	width: auto;
	min-width: 250px;
}
.body-container{
	height: 100%;
	width: 100%;
	display: grid;
	justify-content: stretch;
	align-content: stretch;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: minmax(250px, 30vh) minmax(250px, 30vh) minmax(250px, 30vh);
	grid-auto-rows: minmax(250px, 30vh);
	gap: 15px;
	[draggable="true"] {
		/*
		To prevent user selecting inside the drag source
		*/
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
	.drag{
		border: 2px solid #d3d7dd;
		opacity: 0.8;
	}
}
@media screen and (max-width: 1200px) {
	.body-container{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 15px 0;
		>div{
			width: 100%;
		}
	}
	.not-responsive{
		display: none;
	}
}
.site-name{
	padding: 0 5px;
	font-weight: bold;
	border: 1px solid white;
}
.site-name-more{
	padding: 0 5px;
	font-weight: bold;
	border: 1px solid white;
}

@mixin gridItem($colStart, $rowStart) {
	.grid-col-#{$colStart}-md-row-#{$rowStart}-md {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 1) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 1) !important;
	}
	.grid-col-#{$colStart}-lg-row-#{$rowStart}-md {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 2) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 1) !important;
	}
	.grid-col-#{$colStart}-md-row-#{$rowStart}-lg {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 1) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 2) !important;
	}
	.grid-col-#{$colStart}-lg-row-#{$rowStart}-lg {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 2) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 2) !important;
	}
}

@for $col from 1 to 2 {
	@for $row from 1 to 20 {
	    @include gridItem($col, $row);
	}
}

#dashboard-print-title{
	display: none;
}
@media print{
	#dashboard-print-title{
		display: block;
		font-size: 20px;
		font-weight: bold;
		margin: 20px 0;
	}
}
