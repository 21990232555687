/* mixin.scss */

@function blackOrWhite($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}
// uniquement bouton element-io, pas tester avec bouton simple
@mixin theButton($add,$color) {
  // $dark:$add - #0000f0;
  // $light:$add + #000330;
  $dark:darken($add,10%);
  $light:lighten($add,10%);
  transition: background-color .5s ease;
  background-color:$add !important;
  color:$color !important;
  border:1px solid $light;
  &:hover{
    background:$light !important;
    color:$color !important;
    border:1px solid $dark;
    transition: background-color .5s ease;

  }
  &:active{
    background:$dark !important;
    color:$color !important;
    border-color: $light;
    transition: background-color .0s ease;

  }
  &.is-disabled, &.is-disabled:focus, &.is-disabled:hover{
      border-color: #7a7a7a  !important;
      background-color: #939393 !important;
  }
}
@mixin heightx ($max,$device) {
    @if $device=="mobile" {
        @for $heightx from 1 through $max{
            &.hmx-#{$heightx * 100}{
            height:$heightx * 100px;
          }
        }
    }
   @else {
       @for $heightx from 1 through $max{
           &.hx-#{$heightx * 100}{
           height:$heightx * 100px;
         }
       }
   }
}
@mixin mixinVerifgood() {
    @content
}
@mixin mixinAlphaNumerique($propriete, $valeur) {
    .vg-#{$propriete}-#{$valeur} {
        #{$propriete}: #{$valeur};
    }
}
@mixin mixinNumerique($propriete) {
    @for $valeur from 0 through 100 {
        .vg-#{$propriete}-#{$valeur} {
            #{$propriete}: 1px * $valeur;
        }
    }
}
@include mixinVerifgood() {
    // padding
    @include mixinNumerique(padding);
    @include mixinNumerique(padding-top);
    @include mixinNumerique(padding-right);
    @include mixinNumerique(padding-bottom);
    @include mixinNumerique(padding-left);
    // margin
    @include mixinNumerique(margin);
    @include mixinNumerique(margin-top);
    @include mixinNumerique(margin-right);
    @include mixinNumerique(margin-bottom);
    @include mixinNumerique(margin-left);
    // font
    @include mixinNumerique(font-size);
    @include mixinAlphaNumerique(font-weight, bold);
    @include mixinAlphaNumerique(font-family, sans-serif);

    @include mixinAlphaNumerique(display, flex);
    @include mixinAlphaNumerique(overflow, auto);
}

.vertical-center {
    align-items: center;
    display: flex;
    height: 100%;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.propriete {
    .info {
        .el-input__inner {
            font-size: 14px;
            height: 20px;
            padding: 0px 4px;
        }
    }
}

#documents-attaches-mobile {
    .el-button {
        padding: 14px 20px;
        border-radius: 0px;
    }
}

// style zoom

@mixin mixinZoom {
    .SFM-left-detail-Data {
        align-items: center;
        display: flex;
        height: unset;
        .vg-label {
            color: #999999;
            font-size: 11px;
            height: unset;
            width: 30%;
            &.is-required:before{
                color: #ff4949;
                content: "*";
                margin-right: 4px;
            }
        }
        .vg-value {
            font-size: 12px;
            width: 70%;
        }
        .vg-value-important {
            font-size: 16px;
            font-weight: bold;
            width: 70%;
            .refimg {
                margin-top:-12px;
                margin-right: 10px;
            }
        }
    }
}

#show-tiers {
    @include mixinZoom();
}

#show-contact {
    @include mixinZoom();
}

#show-fm {
    @include mixinZoom();
}

.affectation {
    @include mixinZoom();
}