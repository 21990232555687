
.vg-lieux-table{
    .vg-lieux-demande-intervention{
        height: 85%;
        width: 100%;
        overflow: auto;
    }
}
.loading-overlay{
    position: absolute;
    margin: auto;
    background-color: rgba(255,255,255,0.5);
    width: inherit;
    height: -webkit-fill-available;
    overflow: hidden;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .text{
        background-color:#35b1ea;
        color:white;
        border:none;
        padding:10px;
        font-size:14px;
        display: block;
    }
}
