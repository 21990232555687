
.origine-fm-cell-render{
    display:flex;
    align-items: center;
    img{
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
}
