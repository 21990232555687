
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 5px 10px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  font-size: 12px;
}
.top{
    bottom: 80px !important; /* 30px from the top */
}
.success{
    background-color: #50b659;
    color: white;
    border: 1px solid white;
}
.warning{
    background-color: #f36f3a;
    color: white;
    border: 1px solid white;
}
.info{
    background-color: #059cff;
    color: white;
    border: 1px solid white;
}
.reconnection{
    background-color: #f36f3a;
    color: white;
    border: 1px solid white;
}

@media screen and (max-width: 991.98px) {
    #snackbar {
        width: 99% !important;
        left: 0;
        margin-left: 0;
        bottom: 5px;
    }
}
