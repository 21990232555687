
.maintenance-card-cell-render{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    .col-1{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: inherit;
        width: 20%;
    }
    .col-2{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: inherit;
        width: 80%;
        .main-col{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            height: inherit;
        }
        .footer{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 10px;
            height: inherit;
        }
    }
}
@media screen and (max-width: 600px) {
  	.maintenance-card-cell-render{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        >div{
            width: 100%;
        }
        .row-1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
        }
        .row-2{
            height: 60px;
            overflow: auto;
        }
        .row-3{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        .row-4{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}
