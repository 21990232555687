
#print-fm {
    min-height: 100vh;
    padding: 20px;
    .fm {
        border: 1px solid gray;
        font-size: 14px;
        padding: 20px;
        .fm__header {
            border-bottom: 2px solid gray;
            padding-bottom: 10px;
        }
        table {
            width: 100%;
            td {
                border-bottom: 1px solid gray;
                padding: 10px 0px;
            }
        }
        #fm__body{
            .description-fm{
                margin: 20px 0px;
                .vg-fichesav-description{
                    justify-content: center;
                }
            }
            .row-equipement-composant{
                display: flex;
                justify-content:flex-start;
                align-items:center;
                img{
                    margin-right: 10px;
                }
            }
        }
        .fm__body-observations {
            border: 1px solid gray;
            height: 200px;
            padding: 20px;
        }
        .fm__body-operation{
            margin-top: 25px;
            > div{
                margin-bottom: 5px;
            }
        }
    }
    .operation {
        border: 1px solid gray;
        font-size: 14px;
        padding: 20px;
        // margin-top: 100%;
    }

}
.table{
    border: 1px solid black;
    tr{
        height: 40px;
        td:nth-child(1){
            width: 20%;
            font-size: 14px;
            font-weight: bold;
            padding: 8px;

        }
        td:nth-child(2){
            width: 60%;
            font-size: 15px;
            padding: 10px;
        }
    }
    th, td {
      border: 1px solid black;
    }
}
.header-tabPrint{
    height: 60px;
    background: lightgray !important;
    padding: 20px;
    font-size: 20px;
    width: 100%;
    border: 1px solid black;
    border-bottom: none;
    text-align: center;
}
/*@media print {
  .operation {
      border: 1px solid gray;
      font-size: 14px;
      padding: 20px;
      margin-top: 100%;
  }


}*/

