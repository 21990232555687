
.vg-group{
    width: 100%;
    .title-group{
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #E8EAEE;
        font-weight: bold;
        padding: 0 10px 0 20px;
        font-size: 13px;
        width: 100%;
        img{
            margin-bottom:0;
            margin-right: 10px;
        }
        .vg-button{
            font-weight: normal;
        }
    }
    .container-group{
        padding:10px 5px ;
        padding-bottom: 0;
        height:calc(100% - 40px);
    }

    .container-group.info{
        font-size: 11px;
        text-align: center;
    }
}

