
.consommable-row{
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;
}
.consommable-row-header{
	margin-bottom: 5px;
	font-size: 12px;
}
