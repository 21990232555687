
.vg-libelle-editable{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 25px;
    font-size: 14px;
    .label-name{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        white-space: normal;
        text-align: right;
        font-size: 14px;
        color:#BBBCBE;
        margin-right: 5px;
    }
    .data-label{
        width: 60%;
    }
    .edit-version{
    }
    .read-version{}

}
@media screen and (min-width: 200px) and (max-width: 640px) {
    .vg-libelle-editable{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        font-size: 11px;
        .label-name{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: normal;
            /*text-align: right;*/
            font-size: 11px;
            color:#BBBCBE;
            margin-right: 5px;
        }
        .data-label{
            width: 60%;
        }
        .edit-version{

        }
        .read-version{}

    }
}
