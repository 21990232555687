
.charts-container-inline{
	display: flex;
	justify-content: space-around;
	align-items:center;
	gap:20px;
	height: 100%;
}
.charts-container-col{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items:center;
	gap:20px;
	width: 100%;
}
