
	/* src/views/Maintenance/Maintenance.vue */

.complement-title{
    background-color: rgba(253, 192, 44, 0.1);
    border: 1px solid #FDC02C;
    color: #FDC02C;
    padding: 1px 8px;
    margin: 0 8px;
    font-size: 23px;
    max-width: 30vw;
    width: fit-content;
}

.operation-contener{
    .tableau-operation{
        height: 400px !important;
        margin-bottom: 5%;
        .tag-grid{
            height: 100% !important;
        }
    }
}
.operation-action{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    color: #1286ff;
    font-size: 19px;
    align-items: center;
    height: 40px;
}
@media only screen and (max-width: 768px){
    .header-top-left{
        span {
            font-size:15px;
        }
    }
    #wrapper-footer{
        .uploader-container{

    		.upload-photo{
    			height: 50px;
    			margin: 0;
                border: none;
    			align-items: center;
    		}
    	}
    }

    .tableau-operation{
        border-bottom: 1px solid #dbdbdb;
    }
    .tableau-operation-empty{
        height: 150px !important;
    }
    @mixin heightOperations{
        @for $i from 1 to 20 {
            .tableau-operation-height-#{$i} {
                height: calc($i * 50px) !important;
            }
        }
    }
    @include heightOperations;
}

.vg-flex{
    display: flex;
    .vg-fichesav-origin{
        margin-right: 7px;
    }
}
.section-details{
    display: flex;
    justify-content: space-between;
}

