
.verificationCellRender{
    font-size: 13px;
    // font-family: Arial;
    .conformity{
        float: right;
        margin-right:10px;color: red;
        display: flex;
        .text-conformity{
            float: left;
        }
        img{
            margin-left: 10px;
        }
    }
    .vg-row{
        display: flex;
        margin-bottom: 3px;
        img{
            margin-right: 5px;
        }
    }
    .qrCode-data{
        color: #9e9e9e;
        margin-left: 5px;
        font-size: 12px;
    }
    .vg-first-row{
        height: 20px;
    }
    .path{
        color:#9e9e9e;
        margin-right: 5px;
        font-size: 12px;
        align-self:center;
    }
    .pathEnd{
        color: black;
        font-size: 14px;
        align-self:center;

    }
}

