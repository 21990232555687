
.parameter-user{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	.title{
		color: #02a7f0;
		font-size: 20px;
		width: 100%;
	}
	.headbar{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
		width: 100%;
	}
}
