
.consommable-quantite-cellrenderer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.quantite-info{
    color: blue;
    font-weight: bold;
}
.quantite-warning{
    color: red;
    font-weight: bold;
}
