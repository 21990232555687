
$status-text-color: white;
$status-background-color-default: #ccc;
$status-background-color-en-cours: #ffbf44;
$status-background-color-termine: #45b348;
$status-background-color-a-faire: #ff3f2b;
.inventaire-lieu-cell-render{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
    .main{
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .libel{

        }
        .complement{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 3px;
            .counters-inventoried{

            }
            .status{
                text-transform: uppercase;
                color: $status-text-color;
                padding: 2px 5px;
                background-color: $status-background-color-default;
                margin-left: 10px;
                width:90px;
                font-size: 12px;
                text-align: center;
            }
            .counters-total-en-cours{
                color: $status-background-color-en-cours;
            }
            .status-en-cours{
                background-color: $status-background-color-en-cours;
            }
            .counters-total-termine{
                color: $status-background-color-termine;
            }
            .status-termine{
                background-color: $status-background-color-termine;
            }
            .counters-total-a-faire{
                color: $status-background-color-a-faire;
            }
            .status-a-faire{
                background-color: $status-background-color-a-faire;
            }
        }
    }
    .progress-bar{
        width:100%;
    }
}
