

$background-color: #1F2937;
$background-color-hover: #171F2A;
$font-color: #eee;
$font-section-title-color: #ffbf43;
$font-size: 14px;
$menu-height: 60px;
$menu-mobile-height: calc(var(--vh, 1vh) * 100 - #{$menu-height});


#badge-app-store{
    width: 141px !important;
    height: 45px !important;
}
#badge-google-play{
	width: 163px;
	height:64px;
}
/* Navbar container */
.navbar {
	z-index: 100;
  overflow: hidden;
  background-color: $background-color;
  font-family: Arial;
  border-radius: none !important;
  height: $menu-height;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  .navbar-logo{
	  padding-right:20px;
	  >img{
		  height:20px;
	  }
  }
}
.navbar-main{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 3;
}

/* Links inside the navbar */
.navbar-main a {
  float: left;
  font-size: $font-size;
  color: $font-color;
  text-align: center;
  text-decoration: none;
}

.navbar-secondary{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $font-color;
	gap: 20px;
	height: inherit;
	flex-grow: 2;
	.user-name{
		height: inherit;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.menu-sections{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: $menu-height;
	.menu-sections-container{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: $menu-height;
	}
}
.menu-overlay{
	display: none;
}
.user-profile{
	color: $font-color;
	font-size: 12px;
}

/* The dropdown container */
.menu-section {
  float: left;
  overflow: hidden;
  height: inherit;
}

/* Dropdown button */
.menu-section .menu-section-title {
  font-size: $font-size;
  border: none;
  outline: none;
  color: $font-color;
  /*padding: 14px 16px;*/
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  /*height: 100%;*/
  /*padding: 20px;*/
  height: inherit;
  padding: 0 20px;
  >i{
	  margin-left: 5px;
  }
}

/* Add a background color to navbar links on hover */
.navbar a:hover, .menu-section:hover .menu-section-title {
  background-color: $background-color-hover;
}

/* Dropdown content (hidden by default) */
.menu-sub-sections {
  display: none;
  position: absolute;
  background-color: $background-color;
  border-top: 1px solid #ccc;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001;
  img{
	  height: 20px;
	  width: 20px;
	  margin-right: 10px;
  }
  .only-responsive{
	  display: none;
  }
}

/* Links inside the dropdown */
.menu-sub-sections a {
  float: none;
  color: $font-color;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.menu-sub-sections a:hover {
  background-color: $background-color-hover;
}

/* Show the dropdown menu on hover */
.menu-section-1:hover .menu-sub-section-1 {
  display: block;
}
.menu-section-2:hover .menu-sub-section-2 {
  display: block;
}
.menu-section-3:hover .menu-sub-section-3 {
  display: block;
}
.menu-section-4:hover .menu-sub-section-4 {
  display: block;
}
.menu-section-5:hover .menu-sub-section-5 {
  display: block;
}
.menu-section-1:hover .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-2:hover .menu-sub-section-1 .menu-sub-section-3 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-3:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-4 .menu-sub-section-5{
  display: none;
}
.menu-section-4:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-5{
  display: none;
}
.menu-section-5:hover .menu-sub-section-1 .menu-sub-section-2 .menu-sub-section-3 .menu-sub-section-4{
  display: none;
}

/* responsive */
.navbar .collapse-icon {
  display: none;
}

.only-responsive{
	display: none;
}

.menu-with-submenu {
	position: relative;
	display: inline-block;
	width: 100%;
}
.menu-collapseur{
	float:right;
	margin:auto;
	right:5px;
	height: 100%;
	width:10px;
}
.menu-collapseur-open{
	i{
		transform: rotate(90deg);
	}
}

.menu-with-submenu a {
	display: block;
	text-decoration: none;
	width: 100%;
	img{
		height: 20px;
		width: 20px;
		margin-right: 10px;
	}
}

.submenu {
	position: relative;
	left:auto;
	width:100%;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 0.2s ease-out, transform 0.2s ease-out, height 0.2s ease-out;
}
.submenu a {
	width:100%;
	padding-left: 20px;
}

.menu-with-submenu:hover .submenu {
	max-height: max-content;
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0s;
}

.menu-with-submenu:hover .submenu:focus-within {
  opacity: 1;
  transform: translateY(0);
}

.submenu-visible{
	max-height: max-content;
	opacity: 1;
	transform: translateY(0);
	transition-delay: 0s;
}

@media screen and (max-width: 991.98px) {
  	.navbar a.collapse-icon {
	    float: right;
	    display: block;
	}
	.navbar-logo{
		>img{
			margin-left: 10px;
		}
	}
	.menu-sections{
		display: none;
	}
	.menu-overlay{
		display: none;
	}
	.not-responsive{
		display: none;
	}
	.only-responsive{
		display: block;
	}
}

@media screen and (max-width: 991.98px) {
  	.navbar.responsive {
	  	position: fixed;
  		top: 0;
		left: 0;
  }
  	.navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  	.navbar.responsive{
		.menu-sections-container{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			overflow-y: auto;
			width: inherit;
			border-top: 1px solid #ccc;
			height: $menu-mobile-height;
		}
	  	.menu-sections{
			position: fixed;
			top: $menu-height;
			left: 0;
		    width: 80vw;
			height: $menu-mobile-height;
		    background-color: $background-color;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			/*overflow: auto;*/
			
			.menu-section-recherche-globale{
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 4px;
				width: calc(100% - 20px);
				margin: 10px 10px;
				height: 30px;
				img{
					height: 36px;
				}
			}
			.menu-section-title{
				color: $font-section-title-color;
				font-weight: bold;
				font-size: 13px;
				height: 35px;
				width: 100%;
				text-align: left;
				padding: 0 20px;
			}
			.menu-sub-sections{
				display: block;
			    position: relative;
			    background-color: $background-color;
			    border-top: none;
			    width: 100%;
				padding: 0 10px 0 30px;
			    box-shadow: none;
			    z-index: 100;
			}
			.menu-sub-sections a{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: white;
				font-size: 12px;
			}
			.menu-sub-sections .section{
				display: flex;
				justify-content: space-between;
			}
			.menu-sub-sections .not-responsive{
				display: none;
			}
			.menu-sub-sections-1 .menu-sub-sections-2 .menu-sub-sections-3{
				display: block;
			}
			.menu-section{
				overflow: initial;
				width: 100%;
			}
			.menu-section-1{
				order: 1;
			}
			.menu-section-2{
				order: 3;
			}
			.menu-section-3{
				order: 2;
			}
			.menu-section-4{
				order: 4;
			}
			.menu-section-5{
				order: 5;
			}
			.menu-section-6{
				order: 6;
			}
			.menu-section-7{
				order: 7;
			}
			.menu-section-8{
				order:8;
			}
			.menu-section-9{
				order: 9;
				margin: 20px 0;
				padding: 0 20px;
				width:100%;
				.menu-line{
					border-top: 1px solid white;
				}
			}
			.menu-section-10{
				order: 10;
			}
  		}
		.menu-sections-full-width{
			width: -webkit-fill-available;
		}
		.menu-overlay{
			display: block;
			width: 20vw;
			height: $menu-mobile-height;
			background-color: $background-color;
			opacity: 0.5;
			top: $menu-height;
			left: 80vw;
			position: fixed;
		}
		.menu-overlay-hidden{
			width: 0px;
		}
  	}
	.navbar a:hover, .menu-section:hover .menu-section-title {
	  	background-color: $background-color;
	}
	.section{
		a:first-child{
			width:85%;
		}
		a:last-child{
			width:15%;
		}
		a:hover, a:active{
			background-color: $background-color-hover;
		}
	}
	.section:hover, .section:active{
		background-color: $background-color-hover;
	}
	.menu-sub-sections{
		a:hover, a:active{
			background-color: $background-color-hover;
		}
	}
}
