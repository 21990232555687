
.vg-search-datas-results{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 5px;
	width: inherit;
	min-height: 40px;
	max-height: 60vh;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px #ccc;
	background-color: white;
	overflow-y: auto;
	z-index: 100;
    max-width: -webkit-fill-available;
	.result-no-datas{
		color: #35b1ea;

	}
	.header-results{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
		border-bottom: 1px solid #e5e7eb;
		background-color: #F9FAFB;
		span{
			
			color: #4B5563;
		}
		small{
			
			color: #6B7280;
		}
	}
}
.vg-search-datas-results-slot{
	position: absolute;
	border: none !important;
	box-shadow: none !important;
}
