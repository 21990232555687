
.vg-bon-de-commande-statut-viewer{
	color: white;
	padding: 3px 5px;
	font-size: 12px;
}
.draft{
	background-color: #BDBDBD;	/*gris*/
}
.demande-validation{
	background-color: #FFB300;	/*jaune*/
}
.validation-partielle{
	background-color: #C0CA33;	/*jaune-vert*/
}
.validation-complete{
	background-color: #4CAF50;	/*vert*/
}
.canceled{
	background-color: #8D6E63;	/*marron*/
}
.rejected{
	background-color: #F44336;	/*rouge*/
}
.sended{
	background-color: #26C6DA;	/*bleu clair*/
}
.skip-sending{
	background-color: #26C6DA;	/*bleu foncé*/
}
.delivered{
	background-color: #1E88E5;	/*bleu foncé*/
}
.partially-delivered{
	background-color: #4DB6AC;	/*bleu clair-foncé*/
}
