
.contrat-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
}
