
.vg-search-datas-results{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 5px;
	width: inherit;
	min-height: 40px;
	max-height: 60vh;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px #ccc;
	background-color: white;
	overflow-y: auto;
	z-index: 100;
    max-width: -webkit-fill-available;
	.result-no-datas{
		color: #35b1ea;

	}
	.loading{
		font-weight: normal;
		color: #35b1ea;
	}
	.empty{
		font-weight: normal;
		color: #35b1ea;
		margin: 5px 0;
	}
}
