
.vg-help>i{
	cursor: pointer;
}
.vg-help>i:hover{
	color: #40a9ff;
}
.vg-help-button-menu{
	background-color: #2563EB !important;
	border: none !important;
	color:white;
	border-radius: 6px;
	height:30px;
	padding: 0 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap:8px;
	font-size:13px;
	span{
		line-height: 13px;
		font-weight: bold;
	}
}
.vg-help-button-menu:hover{
	box-shadow: 4px 4px 10px rgba(37,99,235,0.3);
}
button>i{
	color: white;
}
.btn-tutoriels{
	padding-left: 10px !important;
	padding-right: 10px !important;
	background-color: #3999ff;
	border: 1px solid #3999ff;
	box-shadow: 0 1px #3999ff;
	border-radius: 4px;
	text-transform: uppercase;
	color: white !important;
}
.header-content{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}
.section-title{
	font-size: 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-bottom: 10px;
	border-bottom: 2px solid #3999ff;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #333;
	img{
		height: 25px;
		width: 25px;
	}
}
.section-content{
	font-size: 12px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	color: #333;
	margin-bottom: 40px;
	span{
		cursor: pointer;
	}
	span:hover{
		text-decoration: underline;
		color:#3999ff;
	}
}
