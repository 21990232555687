
.daterangepicker{
    .calendars{
        .ranges{
            li{
                padding: 6px 8px !important;
            }
        }
        .calendars-container{
            .table-condensed{
                .today{
                    color: #059cff !important;
                }
                .active:not(.off){
                    color: white !important;
                    background-color: #059cff !important;
                }
                td:hover{
                    font-weight: bold !important;
                }
                .week:hover{
                    font-weight: normal !important;
                }
            }
        }
    }
}
.date-range-picker-footer-date-editable{
    
}
.date-range-picker-footer-date-editable:hover{
    cursor: text;
    font-weight: bold;
}
