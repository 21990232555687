
.vg-tag{
    display: flex;
    width: fit-content;
    padding: 0 6px;
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
    align-items: center;
    float: left;
    margin-right: 5px;
    .icon{
        display: flex;
        height: 13px;
        width: 13px;
        margin-right: 5px;
        img{
            height: 12px;
        }

    }
    .text{
    }
    .cross-close{
        font-size: 10px;
        width: 17px;
        height: 17px;
        margin-right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        &:hover{
            background-color: gray;
            color: white;
        }
    }
    &.blue{
        background-color: rgba(64,158,255,.1);
        border: 1px solid rgba(64,158,255,.2);
        color: #409EFF;
    }
    &.orange{
        background-color: rgba(255, 150, 64, 0.1);
        border: 1px solid rgba(255, 150, 64, 0.2);
        color: rgba(255, 150, 64, 1);
    }
    &.red{
        background-color: rgba(#F56C6C , 0.1);
        border: 1px solid rgba(#F56C6C , 0.2);
        color: #F56C6C;
    }
    &.mauve{
        background-color: rgba(#6f2881 , 0.1);
        border: 1px solid rgba(#6f2881 , 0.2);
        color: #6f2881;
    }
    &.dark-mauve{
        background-color: #6f2881;
        border: 1px solid #6f2881;
        color: white;
    }
}
