
.checkpoints-table{
    width: inherit;
    
    .header{
        display: grid;
        grid-template-columns: 3fr 3fr 2fr 1fr 2fr 1fr;
    }
    
    /*.header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }

    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }*/
}
    #checkpointsTable {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      .complement{
        font-weight: normal !important;
      }
      .complement-sm{
        width: 80px;
      }
      .required{
        color: red;
      }
    }

    #checkpointsTable td, #checkpointsTable th {
      border: 1px solid #dcdfe6;
      padding: 4px;
    }

    #checkpointsTable tr:nth-child(even){
        /*background-color: #whitesmoke;*/
    }

    #checkpointsTable tr:hover {
        /*background-color: whitesmoke;*/
    }

    #checkpointsTable th {
        font-size: 11px;
        padding: 10px;
      text-align: left;
      /*background-color: whitesmoke;*/
    }
    #checkpointsTable tr:last-child{
        margin-top:5px;
        /*background-color:whitesmoke;*/
    }
