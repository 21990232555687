
.vg-tree-node-consommable{
	width: 100%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	.icon-consommable{
		width: 16px;
		height: 16px;
	}
}
.counter{
	border: 1px solid #e8eaee;
	padding: 2px 5px;
}
.see-node{
	color: #3999FF;
	font-size: 10px;
}
