
.viewer-document-container{
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	i{
		margin-right: 5px;
	}
}
.underline{
	color: #3999ff;
	text-decoration: underline;
}
