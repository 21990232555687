

.operations-simple-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .date{
        margin-right: 5px;
    }
}
@media only screen and (max-width: 768px){
    .operations-simple-view{
        font-size: 12px;
    }
}

