
.dates-interventions-table{
	thead{
		th{
			color: #9e9da9;
			font-family: "Open Sans";
		    font-size: 12px;
		    padding-bottom: 10px;
		}
	}
	tbody{
		th{
			padding-right:5px;
			font-weight: normal;
		}
	}
}
.highlight-date{
	color: #0099ff;
}
