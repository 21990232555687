
$inter-color: #fdc02c;
$background-color: #fdc02c;
.vg-fichesav-timeline{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	overflow-x: auto;
	.timeline-part{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		.timeline-phase{
			width:150px;
			margin-top: 1px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			.phase{
				width: 100%;
				text-align: center;
				color: white;
				font-size: 12px;
				font-weight: bold;
				border: 2px solid $inter-color;
				background-color: $background-color;
				padding: 5px;
			}
			.complement{
				margin-top:5px;
				width:100%;
				font-size: 11px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.affectes{
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					border: 1px solid $background-color;
					padding: 5px;
					width: 100%;
					.affecte{
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						gap:5px;
					}
				}
				.comment{
					width: 100%;
					border: 1px solid $background-color;
					padding: 5px;
				}
				.tache{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 5px;
				}
				.link{
					text-decoration: underline;
					color: #059CFF;
					cursor:pointer;
				}
			}
		}
		.timeline-phase-icon{
			margin-top: 0px;
			.phase-icon{
				display: flex;
				justify-content: center;
				align-items: center;
				width:100%;
				.phase-icon-inter{
					border-top: 2px solid $inter-color;
					width: 100%;
					margin-top:-6px;
				}
				.icon{
					width: 40px;
					text-align: center;
					color: $background-color;
					font-size: 12px;
					font-weight: bold;
					border: 2px solid $inter-color;
					background-color: white;
					padding: 5px;
					border-radius: 100%;
				}
			}
		}
		.timeline-inter{
			width:80px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.ecart{
				font-size: 11px;
			}
			.inter{
				border-top: 2px solid $inter-color;
				width: 100%;
			}
		}
	}
}
