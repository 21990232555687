
.vg-equipements-table{
	height: 90%;
    width: 100%;
	.check-lieu-equipements{
		width: 95%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.check-lieu-equipement{
			border-bottom: 1px solid #d7d7d9;
			padding: 5px 0;
			width:100%;
			height: inherit;
		}
	}
	.vg-equipements-picker{
		margin-top:10px;
		.equipement-row{
			border-bottom: 1px solid #CACACB;
		}
	}
	
}
