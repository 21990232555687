
.intervention-row{
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding: 5px 15px;
	font-size: 11px;
	border: 1px solid #d3d7dd;
	margin-bottom: 10px;
	overflow-x: auto;
	cursor: pointer;
	.intervention-main{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 2px;
	}
	.intervention-date{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
}
.intervention-row:hover{
	background-color: whitesmoke;
}
