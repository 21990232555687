
.popup-piece-marker{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    gap: 10px;
    font-size: 12px !important;
    .title{
        margin-bottom: 10px;
    }
    .link{
        text-decoration: underline;
        color: #1e90ff;
        margin: 5px;
        cursor: pointer;
    }
    .info{
        font-style:italic;
        margin: 5px;
    }
    .content-update{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
}
.maintenance-en-cours{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    img{
        width: 15px;
        height: 15px;
    }
}
