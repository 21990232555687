
.icon-name-cell-render{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .main{
        width: calc(100% - 20px);
        margin-right: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        img{
            margin: 0;
        }
        .no-icon{
            height: 25px;
            width: 25px;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    button{
        i{
            color: #3999FF;
        }
    }
    .blue-data{
        font-weight: bold;
        color:#3999ff;
    }
    .link:hover{
        cursor: pointer;
        text-decoration: underline dotted;
    }
}
