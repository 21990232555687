

.container-totaux{
	width: 100%;
	display: grid;
	grid-template-columns: 70% 30%;
	.totaux{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:flex-start;
		gap:5px;
		padding-left: 30%;
		.totaux-row-title{
			color:#0099ff;
			font-size:12px;
		}
		.totaux-row{
			color:#0099ff;
			font-weight: normal;
			display: grid;
			grid-template-columns: 1fr 4fr;
			align-items: baseline;
			gap:10px;
			font-size:12px;
			.title{
				font-size: 26px !important;
			}
			.red-counter{
				color:#d9001b;
				font-size:20px;
				text-align: right;
				width: 50px;
			}
			.green-counter{
				color:#63a103;
				font-size:20px;
				text-align: right;
				width: 50px;
			}
		}
		.totaux-row:hover{
			cursor:pointer;
			font-weight: bold;
		}
	}
	.totaux-reserves{
		display:flex;
		flex-direction:column;
		justify-content:center;
		align-items:flex-start;
		gap:10px;
		.totaux-row-title{
			color:#0099ff;
			font-size:12px;
		}
		.totaux-row{
			color:#0099ff;
			font-weight: normal;
			display: grid;
			grid-template-columns: 1fr 4fr;
			align-items: baseline;
			gap:10px;
			font-size:12px;
			.red-counter{
				color:#d9001b;
				text-align: right;
			}
			.green-counter{
				color:#63a103;
				text-align: right;
			}
		}
	}
}

