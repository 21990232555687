.item .item-image {
    width: 50px;
    height: 34px;
    animation: loader 3s infinite ease-in-out;
    -webkit-animation: loader 3s infinite ease-in-out;
}
.tab{
    width: 112px;
    height: 35px;
    background-color: lightslategrey;
    margin-right:5px;
    animation: blue-loader 3s infinite ease-in-out;
    -webkit-animation: blue-loader 3s infinite ease-in-out;
}
.item .item-tab {
    width: 50px;
    height: 34px;
    animation: loader 3s infinite ease-in-out;
    -webkit-animation: loader 3s infinite ease-in-out;
}

.item .content .title {
    height: 34px;
        width: 100%;
        animation: loader 3s infinite ease-in-out;
        -webkit-animation: loader 3s infinite ease-in-out;
}

.item .content .short-title {
    height: 12px;
    margin: 10px;
    width: 350px;
    animation: loader 1s infinite ease-in-out;
    -webkit-animation: loader 1s infinite ease-in-out;
}

@keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

@-webkit-keyframes loader {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}
@keyframes blue-loader {
    0% {
        background-color: rgba(119,136,153,0.1);
    }
    50% {
        background-color: rgba(145,162,179,0.3);
    }
    100% {
        background-color: rgba(188,200,213,0.1);
    }
}

@-webkit-keyframes blue-loader {
    0% {
        background-color: rgba(119,136,153,0.1);
    }
    50% {
        background-color: rgba(145,162,179,0.3);
    }
    100% {
        background-color: rgba(188,200,213,0.1);
    }
}
