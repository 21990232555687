
.vg-composant-selector-cellrender{
    display: flex;
    align-items: center;
}
.vg-composant-selector{
    height: 100%;

    .tableau{
        height: 90%;
    }
}
