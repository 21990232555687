
.header-equipement{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 15px 0;
    .label{
        font-weight: bold;
        font-size:12px;
    }
    .code{
        color:#bac0c9;
        font-size:11px;
    }
}
