
.files-cell-renderer{
    display: flex;
    justify-content: flex-start;
    >*{
        font-size: 11px;
        border: 1px solid #ccc;
        padding: 0 5px;
    }
    .file{
        cursor: pointer;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
