
$toggler-disabled-color: #cacaca;
.vg-tree-node{
	cursor: pointer;
	.tree-node{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 35px;
		.toggler{
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			>.pending{
				color: $toggler-disabled-color;
			}
		}
		.toggler:hover{
			color: #78208a;
		}
		>.node{
			width: 100%;
		}
		.node-leaf{
			margin-left: 20px;
		}
		>.selected{
			font-weight: bold;
		}
	}
}
@media only screen and (max-width: 1200px){
	.vg-tree-node{
		overflow-y: hidden;
		.tree-node{
			height: 50px;
			overflow-y: hidden;
			.toggler{
				width: 40px;
				height: 50px;
				i{
				    font-size: 1.6em !important;
					color: #5b5e62;
				}
			}
			.node{
				overflow-y: hidden;
			}
			.node-leaf{
				margin-left: 5px;
				overflow-y: hidden;
			}
		}
	}
}
