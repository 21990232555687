
.footer-intervention{
	width: 100px;
	margin-right: 10px;
	.upload-photo{
		height: 40px;
		margin: 0;
		align-items: center !important;
	}
}
.reserves-view{
	margin-bottom: 10px;
	.reserve-equipement-view{
		display:flex;
		justify-content:space-between;
		align-items:center;
		margin-bottom: 5px;
	}
}

.reserve-equipement-add{
	display:flex;
    flex-direction: column;
	justify-content:space-between;
	align-items:baseline;
}
