
.vg-fiche-demande-consommables-table{
    height: 100%;
}
.vg-fiche-demande-consommables-liste{
    height: 300px;
    overflow: auto;
    .empty-consommables-message{
        color: rgb(57, 153, 255);
    }
    .consommable{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom:1px solid #eee;
        cursor: pointer;
    }
    .consommable:hover{
        background-color: whitesmoke;
    }
}
