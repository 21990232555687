
.btn-section{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    button{
        width:100%;
        margin:7px 0;
        height:35px;
    }
}

    .title-fichesav-manangement{
        font-size: 16px;
    }
    .section{
        margin-bottom:10px;
        .name-section{
            font-size: 13px;
            color: #a2a2a2;
            width: 100%;
            height: 25px;
            .name{
                float: left;
                height: 100%;
                width: 40%;
                font-size: 12px;
            }
            .separation{
                width: calc(100% - 40%);
                float: left;
                height: 30%;
                border-bottom: 1px solid #a2a2a2;
            }

        }
        .vg-button-management{
            color: white;
            background-color: #0080C5;
            border: 1px solid #0080C5;
            width: 100%;
            font-size: 14px;
            text-align: left;
            margin-bottom:3px;
            .tag-button{
                font-size: 10px;
                margin-left: 3px;
            }
            img,i{
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
        }

    }
    .vg-button-management-close{
        color: #0080C5 !important;
        border: 1px solid #0080C5 !important;
        font-size: 14px !important;

    }
