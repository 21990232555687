

.bc-total{
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    margin-right: 3%;
    .bc-total-item{
        display:flex;
        justify-content:space-between;
        align-items: baseline;
        .bc-total-item-title{
            width: 100px;
            color: #9e9da9;
            font-family: "Open Sans";
            font-size: 0.85em;
        }
    }
}
