
.prochaine-intervention-cell-render{
    line-height: 0.7;
}
progress:not(value) {
    background-color: whiteSmoke;
}
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 6px;
}
progress[value]::-webkit-progress-value {
    background-color: green;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
.critique{
    progress[value]::-webkit-progress-value {
        background-color: red;
    }
}
.warning{
    progress[value]::-webkit-progress-value {
        background-color: orange;
    }
}
.empty{
    progress[value]::-webkit-progress-value {
        background-color: grey;
    }
}
