
.tui-full-calendar-popup-detail{
    z-index: 1003;
    min-width: 350px !important;
}
.tui-full-calendar-popup-container{
    padding:0;
    min-width: 350px !important;
    font-size: 12px;
    .header{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #d7d7d7;
        padding: 10px 20px;
    }
    .content{
        padding: 10px 20px;
    }
    .footer{
        background-color: #d7d7d7;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
    }
}
.header-title{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    .icon{
        width: 20px;
        height: 20px;
    }
}
.content-grid{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    .grid-title{
        color: #d1d1d1;
    }
    .grid-value{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        .intervention-prevue{
            color: red;
        }
        .intervention-realisee{
            color: #45b348;
        }
        .icon{
            width: 15px;
            height: 15px;
        }
        .affecte{
            
        }
    }
}
