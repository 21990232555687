
.popup-equipement-marker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    font-size: 12px !important;
    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
}
.maintenance-en-cours{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    img{
        width: 15px;
        height: 15px;
    }
}
