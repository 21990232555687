
$toggler-disabled-color: #cacaca;
$node-selected-color: rgb(233,222,237);
$title-bg-color: #E8EAEE;
$context-menu-bg-color: GhostWhite;
$context-menu-border-color: #BDBDBD;
$filter-infos-bg-color: rgba(233,222,237,.4);
$context-menu-option-hover: rgba(0, 0, 0, 0.2);
$tree-height: 300px;
.vg-tree-categories{
	.header{
		.header-title{
            height:40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px;
			background-color: $title-bg-color;
            font-size: 13px;
            padding:0 20px;
            img{
                margin-bottom:0;
                margin-right: 10px;
            }
			.title{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 5px;
			}
			.options{
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
			}
			.toggler{
				margin-right: 10px;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
			.disabled{
				color: $toggler-disabled-color;
			}
			.only-desktop{
				display: block;
			}
			.only-mobile{
				display: none;
			}
		}
		.filter-infos{
			background-color: $filter-infos-bg-color;
			text-align: center;
			padding: 5px 0px;
		}
		.selection-infos{
			background-color: $node-selected-color;
			text-align: center;
			padding: 5px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 10px 5px 20px;
			>div{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				img{
					margin-right:10px;
					height: 20px;
					width: 20px;
				}
			}
			>i{
				cursor: pointer;
			}
		}
	}
	.icon-categorie{
		width: 16px;
		height: 16px;
	}
	.body{
		height:inherit;
		overflow-y: scroll;
	}
	.tree-body-loader{
		background-color: whitesmoke;
		opacity: 0.5;
		position: relative;
		left: 0;
		top: -$tree-height;
		z-index: 1;
		height: 100%;
		width: 100%;
		.loader{
			position: relative;
			left: 50%;
			top: 50%;
			width: 60px;
			height: 60px;
			border: 8px solid #f3f3f3;
			border-radius: 50%;
			border-top: 8px solid #3498db;
			-webkit-animation: spin 2s linear infinite;
			animation: spin 2s linear infinite;
		}
	}
	/*@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}*/
	.context-menu{
		display: block !important;
		position: fixed !important;
		z-index: 2 !important;
		background: $context-menu-bg-color;
		border: 1px solid $context-menu-border-color;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
		list-style: none !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 300px !important;
		.context-menu-options{
			list-style: none;
			margin-block-start: 0 !important;
			margin-block-end: 0 !important;
			padding-inline-start: 0 !important;
			.context-menu-option{
				font-weight: 500;
				font-size: 14px;
				padding: 10px 40px 10px 20px;
				cursor: pointer;
				&:hover {
					background: $context-menu-option-hover;
				}
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			}
		}
	}
}

@media only screen and (max-width: 1200px){
	.vg-tree-lieux{
		.header{
			.header-title{
				padding:0 5px;
				.only-desktop{
					display: none;
				}
				.only-mobile{
					display: block;
				}
			}
		}
	}
}

