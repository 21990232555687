
.progression-row{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 5px;
	padding: 5px 15px;
	font-size: 11px;
	border: 1px solid #d3d7dd;
	margin-bottom: 10px;
	overflow-x: auto;
	cursor: pointer;

}
.progression-row:hover{
	background-color: whitesmoke;
}
