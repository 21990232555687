
.action-menu-aggrid{
    height: 40px;
    width: 40px;
    display: flex;
}
.menu-more{
    height: 30px;
    width: 30px;
    // display: flex;
    align-self: center;
    float: none !important;
    .icone{
        cursor:pointer;
        // align-self: center;
        margin: 0 auto;

        height: 25px;
        width: 25px;
        display: flex;
        border-radius: 30px;
        &:active{
            background-color: #f2f2f2;
        }
        i{
            align-self: center;
            margin: 0 auto;
        }
    }
    .menu{
        font-size: 12px;
        height: max-content;
        width: 150px;
        background: white;
        position: absolute;
        padding:0;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 1;
        transform-origin: center top;
        -webkit-animation-name: transform,opacity; /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: .3s; /* Safari 4.0 - 8.0 */
        animation-name: transform,opacity;
        animation-duration:.3s;
        .item{
            height: 30px;
            width: 100%;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 1px 6px;
            display: flex;
            span{
                align-self:center;
                width: 100%;
                text-align: center;
            }
            &:hover{
                background-color:#f2f2f2 ;
            }
        }
    }
    .bottom-right{
        right: 0;
    }
    .bottom-left{
        left: 0;
    }
    @keyframes transform {
       from { width: 30px;}
       to { width: 150px;}
   }
    @keyframes opacity {
       from {opacity:0;}
       to {opacity: 1; }
   }
 }



