
.vg-files-viewer-list{
	width: 100%;
	>div{
		margin-bottom: 7px;
		display: flex;
		justify-content: space-between;
	}
	>div:hover{
		color:#57c1db;
	}
}
