
.contrat-cell-render{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    .like-a-button{
        color: #673ab7;
        text-decoration: none;
        border: 1px solid #673ab7;
        padding: 5px;
    }
    .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
}
