
.consommable-fournisseurs{
	display: flex;
	justify-content: flex-start;
	align-content: center;
}
.fournisseur{
	border: 1px solid #BBBCBE;
	padding: 5px;
	margin-right: 2px;
	>i{
		cursor: pointer;
	}
}
