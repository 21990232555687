/* app.css */



html , body {
    height: 100%;
    background-color: white;
    font-family: "Helvetica" ;
}
#app{
    height: 100vh;
}
.full-height {
    height: 100%;
}
.full-height-inherit{
    height: inherit;
}

input:focus, select:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

@media print{
    .vg-print-show{
        visibility: visible !important;
    }
    .vg-print-hide{
        visibility:  hidden !important;
    }
}



/* style général appliqué sur les chemins des pièces */
.vg-path {
    color: grey;
    font-size: 11px;
    font-style: italic;
}

.vg-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.vg-vertical-center {
    align-items: center;
    display: flex;
    height: 100%;
}


.SFM-left-detail-Data {
	margin-bottom: 10px;
}
.SFM-left-detail-Data > button{
	margin-left:10px;
}


/* ******* CSS left panel zoom ******* */

.section > .vg-menu-more{
	float: right;
}



.vg-section-title{
	color: #0099FF;
	font-weight: bold;
}

.vg-container-zoom-panel{
	margin:20px;
}

.vg-perfect-center {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}
.vg-path-start{
    color: #999b9e;
    /*font-size: 12px;*/
    white-space: nowrap;
}
.vg-path-end{
    font-weight: bold;
    color:black;
}

.refimg{
    height: 30px;
    width: 30px;
}
.refimg-20{
    height: 20px;
    width: 20px;
	vertical-align:top;	/* alignement de l'image centrée*/
}


.vg-important-cell{
    color:#3999FF;
}

/* SPINNER CLASS */
.custom-spinner{
	font-size: 50px;
}

/* ***************************
     AG GRID style START ag-grid cell row
*****************************  */
/* ag-grid styling (start) */
    /* cell styling (start) */
        .vg-cell-ballam-theme {
            text-align: left;
            font-size: 12px;
            padding-top: 5px;
        }
        .vg-cell {
            text-align: left;
            font-size: 14px;
            padding-left: 24px;
            padding-top: 21px;
            border-bottom: 1px solid #CACACB;
        }
        .vg-cell-mobile-card {
            padding-top: 6px;
            overflow: auto;
            border-bottom: 1px solid #CACACB;
        }
        .vg-cell-mobile {
            text-align: left;
            font-size: 14px;
            padding-left: 24px;
            padding-top: 6px;
            overflow: auto;
            border-bottom: 1px solid #CACACB;
        }


    /* cell styling (end) */




.ag-grid-row-refimg-affectations{
    height:15px;
    width:15px;
    vertical-align:inherit;
    margin-right: 7px;

}
.vg-cell-bold{ /* ag-grid cell*/
	font-weight: bold;
}

.ag-row-focus {
  border: #5b9cd0 1px solid;

}
.ag-cell-focus{

    outline: none;
}



.ag-row.equipement-sorti {
    background-color: #E5E5E5 !important;
    opacity: 0.5
}

.ag-row.equipement-sorti::before {
    border-top: 1px solid rgba(0,0,0, 0.5);
    content:"";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}

/* ***************************
     AG GRID style END ag-grid cell row
*****************************  */


/* ----------------------- */
/* custom scrollbar */
/* ----------------------- */
@media only screen and (min-width: 768px) {
    ::-webkit-scrollbar {
        background: transparent;
        height: 8px;
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: #dadce0;
        min-height: 40px;
    }
}
