
.lieu-mobile-cell-render{
    overflow: auto;
    .libel-piece{
        font-weight: bold;
    }
    .start-path{
        color: gray;
    }
    /*.path{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        .start-path{
            display: flex;
            overflow: scroll;
            width: 50%;
            color: gray;
        }
        .end-path{
            width: 40%;
            text-align: right;
        }
    }*/
    .info-lieu{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .verification-lieu{
            display: flex;
            flex-direction: row;
            align-items: center;
            .badge-verification{
                background-color: #95CB45;
                color: #FFFFFF;
                font-size: 11px;
                padding: 3px 6px ;
                margin-left: 5px;
            }
        }
    }
}
