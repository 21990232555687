
.footer-mode-edition{
    width:100%;
    height:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 20px;
    gap:20px;
    .formulaire-creation-pieces-buttons{
        flex-grow:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        gap:10px;
    }
}
.select-etage{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 24px;
    color: #02a7f0;
}
@media screen and (max-width: 1200px) {
    .select-etage{
        height:50px;
        font-size: 18px;
    }
}

