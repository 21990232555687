
.vg-new-badge{
    display: inline-block;
	background-color: #3999ff;
	position: relative;
	color: white;
	font-size: 11px;
	padding: 0 5px;
	margin-left:0;
}
.negative{
	display: inline;
	background-color: white;
	color: #3999ff;
	font-weight: bold;
}
@media screen and (max-width: 991.98px) {
	.vg-new-badge{
		margin-left:5px;
	}
}
