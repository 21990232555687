
.vg-verification-modal-viewer{
	div{
		>.label:first-child{
			color: #9e9da9;
			padding-right: 10px;
		}
	}
	.link-equipement{
		b:hover{
			color:#3999ff;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.vg-lieu-path-viewer{
		display: inline-flex;
	}
}

