

#ifc-to-pieces-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    tr{
        
        /* padding: 40px; */
        color: grey;
        font-weight: 100 !important;
    }
    th{
        background-color: rgb(244, 246, 247);
    }
    td, th {
        border: 1px solid rgb(212, 218, 224);
        text-align: left;
        padding: 4px;
    }
}
.selectable{
    cursor: pointer;
}
.clickable{
    cursor: pointer;
    border-bottom: 2px dotted #ceceed;
}
.clickable:hover{
        color:#e91e63;
    }
.chips{
    display:flex;
    gap: 5px;
    margin-bottom: 5px;
}
.chip{
    /*width: 200px;*/
    background-color: #ebcf2a;
    border-radius: 8px;
    padding: 3px 10px;
    display: flex;
    gap: 5px;
    justify-content: space-around;
}
.chip-content{
    display: flex;
    align-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    gap:10px;
}
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
    border: 1px solid rgb(203, 207, 209);
    padding: 10px;
    border-color: #cad1d3;
    margin:auto;
}
.label-file:hover {
    color: #6b6f70;
}

// et on masque le input
.file-input {
    display: none;
}
