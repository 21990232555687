
.img-clickable{
	cursor: pointer;
}
.img-clickable:hover{
	border: 1px solid whitesmoke;
}
.no-file{
	color: #e0e0e1;
}
@media print {
	a{
		color: white;
	}
	a[href]:after{
		content: "" !important;
	}
}

