
.categorieIcon{
     float:left;
     margin-top:8px;
     margin-right:10px;
     background-color:white;
     width:10px;
     height:10px;
}
.icon{ /* ag-grid cell*/
    height: 22px;
    width: 22px;
}
@media screen and (max-width: 991.98px) {
    .categorie-cell-render{
        .header{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon{
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }
            .title{
                font-weight:bold;
                margin-right:10px;
            }
            .color{
                background-color:white;
                width:10px;
                height:10px;
            }
        }
        .body{
            padding-left: 35px;
            font-size: 11px;
        }
        border-bottom: 1px solid #CACACB;
        padding: 10px 0;
        height: 100%;
        width: 100%;
    }
}
